import { Box, Button, CircularProgress, FormControl, Grid, Hidden, lighten, makeStyles, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, Typography } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import React, { useEffect, useState } from 'react';
import Plot from 'react-plotly.js'
import { CSVLink } from "react-csv";
import Checkbox from "@material-ui/core/Checkbox";
import { useStyles} from "../qualitative compatibility/bubblechart/utils";
import Axios from 'axios';
import ResistanceOutput from '../qualitative compatibility/bubblechart/ResistanceOutput';
import { ValidatorForm, TextValidator} from 'react-material-ui-form-validator';
import './quantitative.css';
import AutocompleteFields from '../common/AutocompleteFields';
import Alert from '@material-ui/lab/Alert';
import axios from '../common/AxiosConfig';
import InputWithoutValidator from '../common/InputWithoutValidator';
const Quantitative = (props) => {
  let intialState={}
  let inputFieldsDataConst = []

  // step 1
  if(props.chartType==='chemicalCompatibilitySelectExposure'){
     intialState = {
      exposure: null,conc: null, temp: null, time: null, pressure: null,
    }
    inputFieldsDataConst=[

      {
        id: "exposure",
        name: "exposure",
        displayName: "Exposure medium",
        // option:polymerOption
        value:null
      },
      {
        id: "conc",
        name: "conc",
        displayName: "Exposure Concenteration (vol%)",
        // option:concenterationOption
        value:null
      },
      {
        id: "temp",
        name: "temp",
        displayName: "Exposure Temperature (\u00B0C)",
        // option:temperatureOption
        value:null
      },
      {
        id: "time",
        name: "time",
        displayName: "Exposure Time (h)",
        // option:timeOption
        value:null
      },
      {
        id: "pressure",
        name: "pressure",
        displayName: "Exposure Pressure (bar)",
        // option:pressureOption
        value:null
      },
    ]
  }
  else if (props.chartType==='volChangeTime' || props.chartType==='volChangePressure'){
    intialState = {
      polymer: null, trade: null,exposure: null, conc: null, temp: null
    }
    inputFieldsDataConst=[

      {
        id: "polymer",
        name: "polymer",
        displayName: "Polymer",
        // option:polymerOption
        value:null
      },
      {
        id: "trade",
        name: "trade",
        displayName: "Trade Name",
        // option:tradeNameOption
        value:null
      },
      {
        id: "exposure",
        name: "exposure",
        displayName: "Exposure medium",
        // option:timeOption
        value:null
      },
      {
        id: "conc",
        name: "conc",
        displayName: "Exposure Concenteration (vol%)",
        // option:concenterationOption
        value:null
      },
      {
        id: "temp",
        name: "temp",
        displayName: "Exposure Temperature (\u00B0C)",
        // option:temperatureOption
        value:null
      },
     
      
    ]
  }
  else if (props.chartType==='qualitativeContour'){
    intialState = {
      polymer: null, trade: null,exposure: null, report: null
    }
    inputFieldsDataConst=[

      {
        id: "polymer",
        name: "polymer",
        displayName: "Polymer",
        // option:polymerOption
        value:null
      },
      {
        id: "trade",
        name: "trade",
        displayName: "Trade Name",
        // option:tradeNameOption
        value:null
      },
      {
        id: "exposure",
        name: "exposure",
        displayName: "Exposure medium",
        // option:timeOption
        value:null
      },
      {
        id: "report",
        name: "report",
        displayName: "Source Report",
        // option:temperatureOption
        value:null
      },   
      
    ]
  }
  else if (props.chartType==='qualitativeContourHighTemp'){
    intialState = {
      polymer: null, trade: null,exposure: null, report: null
    }
    inputFieldsDataConst=[

      {
        id: "polymer",
        name: "polymer",
        displayName: "Polymer",
        // option:polymerOption
        value:null
      },
      {
        id: "trade",
        name: "trade",
        displayName: "Trade Name",
        // option:tradeNameOption
        value:null
      },
      {
        id: "exposure",
        name: "exposure",
        displayName: "Exposure medium",
        // option:timeOption
        value:null
      },
      {
        id: "report",
        name: "report",
        displayName: "Source Report",
        // option:temperatureOption
        value:null
      },   
      
    ]
  }
  else if (props.chartType==='volChangeTemp'){
    intialState = {
      polymer: null, trade: null,exposure: null, conc: null, time: null,pressure:null
    }
    inputFieldsDataConst=[

      {
        id: "polymer",
        name: "polymer",
        displayName: "Polymer",
        // option:polymerOption
        value:null
      },
      {
        id: "trade",
        name: "trade",
        displayName: "Trade Name",
        // option:tradeNameOption
        value:null
      },
      {
        id: "exposure",
        name: "exposure",
        displayName: "Exposure medium",
        // option:timeOption
        value:null
      },
      {
        id: "conc",
        name: "conc",
        displayName: "Exposure Concenteration (vol%)",
        // option:concenterationOption
        value:null
      },
      {
        id: "time",
        name: "time",
        displayName: "Exposure Time (h)",
        // option:temperatureOption
        value:null
      },
      {
        id: "pressure",
        name: "pressure",
        displayName: "Exposure Pressure (bar)",
        // option:temperatureOption
        value:""
      },
     
      
    ]
  }
  else if(props.chartType==='dataMaterialsCO2'){
    intialState = {
      polymer: null, trade: null,polymerSupplier: null, exposure: null, exposureClass: null,time:null
    }
    inputFieldsDataConst=[

      {
        id: "polymer",
        name: "polymer",
        displayName: "Polymer",
        // option:polymerOption
        value:null
      },
      {
        id: "trade",
        name: "trade",
        displayName: "Trade Name",
        // option:tradeNameOption
        value:null
      },
      {
        id: "polymerSupplier",
        name: "polymerSupplier",
        displayName: "Supplier",
        // option:timeOption
        value:null
      },
      {
        id: "exposure",
        name: "exposure",
        displayName: "Exposure medium",
        // option:timeOption
        value:null
      },
      {
        id: "exposureClass",
        name: "exposureClass",
        displayName: "Exposure medium class",
        // option:concenterationOption
        value:null
      },
      {
        id: "time",
        name: "time",
        displayName: "Exposure Time (day)",
        // option:timeOption
        value:null
      },
     
     
      
    ]
  }
  else if(props.chartType==='dataImpurityOnly'){
    intialState = {
      polymer: null, trade: null,chemical: null,report:null
    }
    inputFieldsDataConst=[

      {
        id: "polymer",
        name: "polymer",
        displayName: "Polymer",
        // option:polymerOption
        value:null
      },
      {
        id: "trade",
        name: "trade",
        displayName: "Trade Name",
        // option:tradeNameOption
        value:null
      },
      {
        id: "chemical",
        name: "chemical",
        displayName: "Typical impurity",
        // option:timeOption
        value:null
      },
      {
        id: "report",
        name: "report",
        displayName: "Source report",
        // option:temperatureOption
        value:null
      },
     
     
      
    ]
  }
  else if(props.chartType==='DataImpurityCO2'){
    intialState = {
      polymer: null, trade: null,chemical: null, pressure: null, relative: null,report:null
    }
    inputFieldsDataConst=[

      {
        id: "polymer",
        name: "polymer",
        displayName: "Polymer",
        // option:polymerOption
        value:null
      },
      {
        id: "trade",
        name: "trade",
        displayName: "Trade Name",
        // option:tradeNameOption
        value:null
      },
      {
        id: "chemical",
        name: "chemical",
        displayName: "Typical impurity",
        // option:timeOption
        value:null
      },
      {
        id: "pressure",
        name: "pressure",
        displayName: "Exposure Pressure (bar)",
        // option:temperatureOption
        value:null
      },
      {
        id: "relative",
        name: "relative",
        displayName: "CO2(%) + impurity(%)",
        // option:concenterationOption
        value:null
      },
      {
        id: "report",
        name: "report",
        displayName: "Source report",
        // option:temperatureOption
        value:null
      },
     
     
      
    ]
  }
  else if(props.chartType==='openDataORings'){
    intialState = {
      polymer: null, material: null, pressure: null, temp: null,ageing:null,compression:null
    }
    inputFieldsDataConst=[

      {
        id: "polymer",
        name: "polymer",
        displayName: "Polymer",
        // option:polymerOption
        value:null
      },
      {
        id: "material",
        name: "material",
        displayName: "Material Specification",
        // option:tradeNameOption
        value:null
      },
      {
        id: "pressure",
        name: "pressure",
        displayName: "Exposure Pressure (bar)",
        // option:temperatureOption
        value:null
      },
      {
        id: "temp",
        name: "temp",
        displayName: "Exposure Temperature (\u00B0C)",
        // option:temperatureOption
        value:null
      },
   
      {
        id: "ageing",
        name: "ageing",
        displayName: "Ageing Period (day)",
        // option:concenterationOption
        value:null
      },
      {
        id: "Compression",
        name: "Compression",
        displayName: "Compression (%)",
        // option:temperatureOption
        value:null
      },
     
     
      
    ]
  }
  else if(props.chartType==='quantitativesealsco2'){
    intialState = {
      polymer: null, material: null, compression: null, compTime: null,releaseTime:null
    }
    inputFieldsDataConst=[

      {
        id: "polymer",
        name: "polymer",
        displayName: "Polymer",
        // option:polymerOption
        value:null
      },
      {
        id: "material",
        name: "material",
        displayName: "Material Specification",
        // option:tradeNameOption
        value:null
      },
      {
        id: "Compression",
        name: "Compression",
        displayName: "Compression (%)",
        // option:temperatureOption
        value:null
      },
      {
        id: "compressionTime",
        name: "compressionTime",
        displayName: "Compression Time (h)",
        // option:temperatureOption
        value:null
      },
   
      {
        id: "release",
        name: "release",
        displayName: "ReleaseTime (h)",
        // option:concenterationOption
        value:null
      },

    ]
  }
  else if(props.chartType==='quantitativeData'){
    intialState = {
      polymer: null, trade: null, polymerThickness: null, pressure: null,temp:null,decompressionTime:null,time:null
    }
    inputFieldsDataConst=[

      {
        id: "polymer",
        name: "polymer",
        displayName: "Polymer",
        // option:polymerOption
        value:null
      },
      {
        id: "trade",
        name: "trade",
        displayName: "Trade Name",
        // option:tradeNameOption
        value:null
      },
      {
        id: "polymerThickness",
        name: "polymerThickness",
        displayName: "Polymer Thickness (mm)",
        // option:temperatureOption
        value:null
      },
      {
        id: "pressure",
        name: "pressure",
        displayName: "Exposure Pressure (bar)",
        // option:pressureOption
        value:null
      },
      {
        id: "temp",
        name: "temp",
        displayName: "Exposure Temperature (\u00B0C)",
        // option:temperatureOption
        value:null
      },
      {
        id: "decompressionTime",
        name: "decompressionTime",
        displayName: "Decompression Time (h)",
        // option:temperatureOption
        value:null
      },
      {
        id: "time",
        name: "time",
        displayName: "Exposure Time (h)",
        // option:timeOption
        value:null
      },
    

    ]
  }
  else if(props.chartType==='dataConsolv'){
    intialState = {
      polymer: null, trade: null, polymerSupplier: null, solvent: null,properties:null
    }
    inputFieldsDataConst=[

      {
        id: "polymer",
        name: "polymer",
        displayName: "Polymer",
        // option:polymerOption
        value:null
      },
      {
        id: "trade",
        name: "trade",
        displayName: "Trade Name",
        // option:tradeNameOption
        value:null
      },
      {
        id: "polymerSupplier",
        name: "polymerSupplier",
        displayName: "Polymer Supplier",
        // option:temperatureOption
        value:null
      },
      {
        id: "solvent",
        name: "solvent",
        displayName: "Solvent",
        // option:pressureOption
        value:null
      },
      {
        id: "properties",
        name: "properties",
        displayName: "Properties",
        // option:temperatureOption
        value:null
      },

    ]
  }
  else {
     intialState = {
      polymer: null, trade: null, conc: null, temp: null, time: null, pressure: null,
    }
    inputFieldsDataConst=[

      {
        id: "polymer",
        name: "polymer",
        displayName: "Polymer",
        // option:polymerOption
        value:null
      },
      {
        id: "trade",
        name: "trade",
        displayName: "Trade Name",
        // option:tradeNameOption
        value:null
      },
      {
        id: "conc",
        name: "conc",
        displayName: "Exposure Concenteration (vol%)",
        // option:concenterationOption
        value:null
      },
      {
        id: "temp",
        name: "temp",
        displayName: "Exposure Temperature (\u00B0C)",
        // option:temperatureOption
        value:null
      },
      {
        id: "time",
        name: "time",
        displayName: "Exposure Time (h)",
        // option:timeOption
        value:null
      },
      {
        id: "pressure",
        name: "pressure",
        displayName: "Exposure Pressure (bar)",
        // option:pressureOption
        value:null
      },
    ]
  }

  const [tableHeaders, setTableHeaders] = useState([])
const [updatedHeader, setUpdatedHeader] = useState([])
  const [page, setPage] = React.useState(0);
const [rowsPerPage, setRowsPerPage] = React.useState(5);
const classes = useStyles();
const [filteredPosts, setFilteredPosts] = React.useState([]);
const [select, setSelect] = useState([])
const [polymerOption, setPolymerOption] = useState([])
const [exposureOption, setExposureOption] = useState([])
const [tradeOption, setTradeOption] = useState([])
const [concenterationOption, setConcenterationOption] = useState([])
const [temperatureOption, setTemperatureOption] = useState([])
const [chemicalOption, setChemicalOption] = useState([])
const [relativeMixOption, setRelativeMixOption] = useState([])
const [sourceReportOption, setSourceReportOption] = useState([])
const [materialOption, setMaterialOption] = useState([])
const [ageingOption, setAgeingOption] = useState([])
const [compressionOption , setCompressionOption ] = useState([])
const [weight, setWeight] = useState([])
const [density, setDensity] = useState([])
const [timeOption, setTimeOption] = useState([])
const [pressureOption, setPressureOption] = useState([])
console.log('pressureOption: ', pressureOption);
const [compressionTimeOption, setCompressionTimeOption] = useState([])
const [releaseTimeOption, setReleaseTimeOption] = useState([])
const [polymerThicknessOption, setPolymerThicknessOption] = useState([])
const [decompressionTimeOption, setDecompressionTimeOption] = useState([])
const [polymerSupplierOption, setPolymerSupplierOption] = useState([])
const [solventOption, setSolventOption] = useState([])
const [propertiesOption, setPropertiesOption] = useState([])
const [exposureClassOption, setExposureClassOption] = useState([])
const [selected, setSelected] = useState([]);
const [tradeNameSelected, setTradeNameSelected] = useState([]);
const [exposureSelected, setExposureSelected] = useState([])
const [sourceReportSelected, setSourceReportSelected] = useState([])
const [concenterationSelected, setConcenterationSelected] = useState([])
const [tempSelected, setTempSelected] = useState([])
const [timeSelected, setTimeSelected] = useState([])
const [pressureSelected, setPressureSelected] = useState([])
const [inputData, setInputData] = useState(intialState)
console.log('inputData: ', inputData);
const [inputFieldsData, setInputFieldsData] = useState(inputFieldsDataConst)
const [concenteration, setConcenteration] = useState([])
const [temperature, setTemperature] = useState([])

const [mechnicalCompatibility, setMechnicalCompatibility] = useState({})
const [resistance, setResistance] = useState([])
const [DpData, setDpData] = useState([])
const [loading, setLoading] = useState(false)
const [flag, setFlag] = useState(false)
const [axisInput, setAxisInput] = useState({x:[],y:[]})
const [resistanceInputFiled, setResistanceInputFiled] = useState([])
const [multipleInput, setMultipleInput] = useState([])
const [trace, setTrace] = useState([])
const [dataConsolvFilteredData, setDataConsolvFilteredData] = useState([])
const [contourData, setContourData] = useState([])
console.log("inputFieldsData",inputFieldsData)
  let options=[];
  let tempExposure=[];
  let reportOption=[];
  let tradeNameOption=[];
  let tempConcenteration=[];
  let tempTime=[];
  let tempPressure=[];
  let tempTemperature=[];
  let tempChemicalOption=[]
  let tempRelativeOption=[]
  let tempSourceReportOption=[];
  let tempMaterial=[]
  let tempAgeing=[]
  let tempCompression=[]
  let tempCompressionTime=[]
  let tempReleaseTime=[]
  let tempPolymerThickness=[]
  let tempDecompressionTime=[]
  let tempPolymerSupplier=[]
  let tempSolvent=[]
  let tempProperties=[]
  let tempExposureClass=[]
  let { polymer, trade, conc, temp, time, pressure,exposure,chemical,relative,report,material,ageing,compression,compTime,releaseTime,polymerThickness,decompressionTime,polymerSupplier,solvent,properties,exposureClass} =inputData

  const form = React.createRef();
  useEffect(() => {
    // step 2
    let url=''
    let params={}
    setLoading(true)
    if(props?.chartType==='selectPolymer'  || props?.chartType==='chemicalCompatibilitySelectExposure' || props?.chartType==='mechnicalCompatibility' || props?.chartType==='volChangeTime' || props?.chartType==='volChangeTemp' || props?.chartType==='volChangePressure'){
      // setLoading(false)
      // setDpData(selectPolymer)
      url="https://digipol.azurewebsites.net/dp_quant_data"
    }
    else if(props?.chartType==='qualitativeContour'){
    url='https://digipol.azurewebsites.net/dp_qual_data'
    // params={
    //   name:"DP_qual",
    //   trade:"nan",
    //   pol:'ABS',
    //   exp:"Acetic Acid",
    //   source:'Chemical Compatibility Chart.pdf'
    // }
    }
    else if(props?.chartType==='DataImpurityCO2'){
    url='https://digipol.azurewebsites.net/dp_ccs_06quant_data'
    }
    else if(props?.chartType==='dataImpurityOnly'){
    url='https://digipol.azurewebsites.net/dp_ccs_01qual_data'
    }
    else if(props?.chartType==='dataMaterialsCO2'){
    url='https://digipol.azurewebsites.net/dp_ccs_08qual_data'
    }
    else if(props?.chartType==='qualitativeContourHighTemp'){
    url='https://digipol.azurewebsites.net/dp_qual_ht1_data'
    }
    else if(props?.chartType==='openDataORings'){
    url='https://digipol.azurewebsites.net/dp_ccs_0405quant_data'
    }
    else if(props?.chartType==='quantitativesealsco2'){
    url='https://digipol.azurewebsites.net/dp_ccs_09_data'
    }
    else if(props?.chartType==='quantitativeData'){
    url='https://digipol.azurewebsites.net/dp_ccs_0203quant_data'
    }
    else if(props?.chartType==='dataConsolv'){
    url='https://digipol.azurewebsites.net/dp_ccs_10_data'
    }
    const token = localStorage.getItem('accessToken');
      axios({
        method:"GET",
        url:url,
        // params:props.chartType==='qualitativeContour'?
        //   params
        // :""
        headers: {
          Authorization: `Bearer ${token}`,
      },
      })
      .then(res=>{
        let data=res?.data?.data
        
         if(props?.chartType==='mechnicalCompatibility'){
          setLoading(false)
          const result = data.map(
            obj => Object.fromEntries(
              Object.entries(obj)
                .map(([key, val]) => [key, String(val)])
            )
          );
          let temp=result?.filter(o => (o['Change in hardness (IRHD)'] || o['Change in tensile strength (%)'] || o['Change in elongation at break (%)']));
          setDpData(temp)
        }
        else if(props?.chartType==='volChangePressure'){
          setLoading(false)
          // let data=res?.data?.data
          const result = data.map(
            obj => Object.fromEntries(
              Object.entries(obj)
                .map(([key, val]) => [key, String(val)])
            )
          );
          let arr =result?.filter(o => o['Pressure (bar)']);
          setLoading(false)
        
          

            setDpData(arr)
          
        }
        // else if(props.chartType==='qualitativeContour'){
        //   // setCobtourData()
        //   setLoading(false)
        //   console.log("contour",res?.data)
        //   setContourData(res?.data)
        //   setDpData(res?.data)
        // }
        else{
          // let data=res?.data?.data
          let result = data.map(
            obj => Object.fromEntries(
              Object.entries(obj)
                .map(([key, val]) => [key, String(val)])
            )
          );
          if(props.chartType==='dataMaterialsCO2'){
            const renameMap = { Material:'Polymer'};

            const newArr = result.map(
              obj => Object.fromEntries(Object.entries(obj).map(
                ([key, value]) => [renameMap[key] ?? key, value]
              ))
            );
            
            setDpData(newArr)
            setLoading(false)


          }
          else{
            setLoading(false)
            setDpData(result)
          }
         
        }
        // setFilteredPosts(res?.data?.data)
      })

  }, [props.chartType])

  console.log("dpData",DpData)
  useEffect(() => {
   setSelected(props.polymer)
  //  setExposureSelected([props.exposure])
  }, [props.polymer])
  

console.log("contourData",contourData)
    useEffect(() => {
      //Filter options updated so apply all filters here
      let { polymer, trade, conc, temp, time, pressure,exposure,chemical,relative,report,material,ageing,compression,compTime,releaseTime,polymerThickness,decompressionTime,polymerSupplier,solvent,properties,exposureClass} =inputData

      let result = [...DpData];
      result=polymer?.length>0? filterPolymer(result,"Polymer",polymer) :result
      if(trade!=="No Data"){

        result=trade?.length>0? filterPolymer(result,"Trade name",trade) :result
      }
      result=exposure?.length>0? filterPolymer(result,"Exposure medium",exposure) :result
      if(props.chartType==='qualitativeContourHighTemp'){
      result=conc?.length>0? filterPolymer(result,"Concentration (vol%)",conc) :result

      }
      else{

        result=conc?.length>0? filterPolymer(result,"Concentration of medium (vol%)",conc) :result
      }
      if(props.chartType==='openDataORings' || props.chartType==='quantitativeData'){

        result=temp?.length>0? filterPolymer(result,"Temperature (C)",temp) :result
      }
      else{

        result=temp?.length>0? filterPolymer(result,"Temperature of medium (C)",temp) :result
      }
      if(props.chartType==='quantitativeData'){
        
        result=time?.length>0? filterPolymer(result,"Exposure time (h)",time) :result
      }
      else if(props.chartType==='dataMaterialsCO2'){
        
        result=time?.length>0? filterPolymer(result,"Exposure time (day)",time) :result
      }
      else{

        result=time?.length>0? filterPolymer(result,"Duration of exposure (h)",time) :result
      }
      // result=tempConcenteration?.length>0? filterPolymer(result,"Concentration (vol%)",tempConcenteration) :result
      result=pressure?.length>0? filterPolymer(result,"Pressure (bar)",pressure) :result 
      result=chemical?.length>0? filterPolymer(result,"Chemical Component",chemical) :result 
      result=relative?.length>0? filterPolymer(result,"Relative mix (%)",relative) :result 
      if(props.chartType==='dataImpurityOnly' || props.chartType==='DataImpurityCO2'){

        result=report?.length>0? filterPolymer(result,"Source/Author",report) :result 
      }
      else if(props.chartType==='qualitativeContourHighTemp'){

        result=report?.length>0? filterPolymer(result,"Report source",report) :result 
      }

      else{
        result=report?.length>0? filterPolymer(result,"Report name",report) :result 
   
      }
      // result=report?.length>0? filterPolymer(result,"Report name",report) :result 
      result=material?.length>0? filterPolymer(result,"Material Specification",material) :result 
      result=ageing?.length>0? filterPolymer(result,"Ageing period (day)",ageing) :result 
      result=compression?.length>0? filterPolymer(result,"Compression (%)",compression) :result 
      result=compTime?.length>0? filterPolymer(result,"Compression time (h)",compTime) :result 
      result=releaseTime?.length>0? filterPolymer(result,"Release time (h)",releaseTime) :result 
      result=polymerThickness?.length>0? filterPolymer(result,"Thickness (mm)",polymerThickness) :result 
      result=decompressionTime?.length>0? filterPolymer(result,"Decompression time (h)",decompressionTime) :result 
      result=polymerSupplier?.length>0? filterPolymer(result,"Supplier",polymerSupplier) :result 
      result=solvent?.length>0? filterPolymer(result,"Solvent",solvent) :result 
      result=properties?.length>0? filterPolymer(result,"Attribute",properties) :result 
      result=exposureClass?.length>0? filterPolymer(result,"Exposure medium class",exposureClass) :result 

      setFilteredPosts(result);
  // }, [exposureSelected, selected,sourceReportSelected,tradeNameSelected,DpData,concenterationSelected,tempSelected,pressureSelected,timeSelected]);
  }, [inputData,inputData.polymer]);



  useEffect(() => {
    options=getPolymer("Polymer")?.sort()
    tradeNameOption=getTradeNameValues("Polymer")?.filter(v=>v!='')?.sort()
    // if(props.chartType==='qualitativeContour' || tradeNameOption[0]===''){
    //  tradeNameOption[0]='No Data'
    // }
    tempExposure=getExposureMedium("Exposure medium")?.sort()
    if(props.chartType==='qualitativeContourHighTemp'){
    tempConcenteration=getConceteration("Concentration (vol%)")?.sort()

    }
    else{

      tempConcenteration=getConceteration("Concentration of medium (vol%)")?.sort()
    }
    console.log("tempConcenteration",tempConcenteration)
    if(props.chartType==='openDataORings' || props.chartType==='quantitativeData'){

      tempTemperature=getTemperature("Temperature (C)")?.sort()
    }
    else{
      tempTemperature=getTemperature("Temperature of medium (C)")?.sort()
    }
    if(props.chartType==='quantitativeData'){

      tempTime=getExposureTime("Exposure time (h)")?.filter(v=>v!='')?.sort()
    }
    else if(props.chartType==="dataMaterialsCO2"){
      
      tempTime=getExposureTime("Exposure time (day)")?.sort()

    }
    else{

      tempTime=getExposureTime("Duration of exposure (h)")?.filter(v=>v!='')?.sort()
    }
    tempPressure=getExposurePressure("Pressure (bar)")?.filter(v=>v!='')?.sort()
    tempChemicalOption=getChemicalImpurity("Chemical Component")?.sort()
    tempRelativeOption=getRelativeMix("Relative mix (%)")?.sort() 
   if(props.chartType==='dataImpurityOnly' || props.chartType==='DataImpurityCO2'){

     tempSourceReportOption=getSourceReport("Source/Author")?.sort()
   }
   else{
    tempSourceReportOption=getSourceReport("Report source")?.sort()

   }
  
    tempMaterial=getMaterial("Material Specification")?.sort()
    tempAgeing=getAgeing("Ageing period (day)")?.sort()
    tempCompression=getCompression("Compression (%)")?.sort()
    tempCompressionTime=getCompressionTime("Compression time (h)")?.sort()
    tempReleaseTime=getReleaseTime("Release time (h)")?.sort()
    tempPolymerThickness=getPolymerThickness("Thickness (mm)")?.sort()
    tempDecompressionTime=getDecompressionTime("Decompression time (h)")?.sort()
    tempPolymerSupplier=getSupplier("Supplier")?.sort()
    tempSolvent=getSolvent("Solvent")?.sort()
    tempProperties=getProperties("Attribute")?.sort()
    tempExposureClass=getExposureClass("Exposure medium class")?.sort()
    setPolymerOption(options.sort())
    setTradeOption(tradeNameOption.sort())
    setExposureOption(tempExposure.sort())
    // setSourceReportOption(reportOption)
    setConcenterationOption(tempConcenteration.sort())
    setTemperatureOption(tempTemperature.sort())
    setTimeOption(tempTime.sort())
    setPressureOption(tempPressure.sort())
    setChemicalOption(tempChemicalOption.sort())
    setRelativeMixOption(tempRelativeOption.sort())
    setSourceReportOption(tempSourceReportOption.sort())
    setMaterialOption(tempMaterial.sort())
    setAgeingOption(tempAgeing.sort())
    setCompressionOption(tempCompression.sort())
    setCompressionTimeOption(tempCompressionTime.sort())
    setReleaseTimeOption(tempReleaseTime.sort())
    setPolymerThicknessOption(tempPolymerThickness.sort())
    setDecompressionTimeOption(tempDecompressionTime.sort())
    setPolymerSupplierOption(tempPolymerSupplier.sort())
    setSolventOption(tempSolvent.sort())
    setPropertiesOption(tempProperties.sort())
    setExposureClassOption(tempExposureClass.sort())

  }, [DpData,inputData])

  console.log("tradeOption",tradeOption)
  useEffect(() => {
    // step 3
    let arr=[]
    if(props.chartType==='chemicalCompatibilitySelectExposure'){
      arr=[exposureOption,concenterationOption,temperatureOption,timeOption.filter(Number),pressureOption]
    }
    else if(props.chartType==='volChangeTime' || props.chartType==='volChangePressure'){
      arr=[polymerOption,tradeOption,exposureOption,concenterationOption,temperatureOption]
    }
    else if(props.chartType==='qualitativeContour'){
      arr=[polymerOption,tradeOption,exposureOption,sourceReportOption,]
    }
    else if(props.chartType==='qualitativeContourHighTemp'){
      arr=[polymerOption,tradeOption,exposureOption,sourceReportOption,]
    }
    else if(props.chartType==='volChangeTemp'){
      arr=[polymerOption,tradeOption,exposureOption,concenterationOption,timeOption.filter(Number),pressureOption]
    }
    else if(props.chartType==='dataImpurityOnly'){
      arr=[polymerOption,tradeOption,chemicalOption,sourceReportOption]
    }
    else if(props.chartType==='DataImpurityCO2'){
      arr=[polymerOption,tradeOption,chemicalOption,pressureOption,relativeMixOption,sourceReportOption]
    }
    else if(props.chartType==='dataMaterialsCO2'){
      arr=[polymerOption,tradeOption,polymerSupplierOption,exposureOption,exposureClassOption,timeOption]
    }
    else if(props.chartType==='openDataORings'){
      arr=[polymerOption,materialOption,pressureOption,temperatureOption,ageingOption,compressionOption]
    }
    else if(props.chartType==='quantitativesealsco2'){
      arr=[polymerOption,materialOption,compressionOption,compressionTimeOption,releaseTimeOption]
    }
    else if(props.chartType==='quantitativeData'){
      arr=[polymerOption,tradeOption,polymerThicknessOption,pressureOption,temperatureOption,decompressionTimeOption,timeOption]
    }
    else if(props.chartType==='dataConsolv'){
      arr=[polymerOption,tradeOption,polymerSupplierOption,solventOption,propertiesOption]
    }
else{
  arr=[polymerOption,tradeOption,concenterationOption,temperatureOption,timeOption.filter(Number),pressureOption]
}

    let x=inputFieldsData.map((x,i)=>(
      {...x,option:arr[i].sort((a, b) => a - b)}
      ))
  

      setInputFieldsData(x)
  }, [polymerOption,tradeOption,exposureOption,concenterationOption,temperatureOption,timeOption,pressureOption,sourceReportOption])
  console.log('timeOption',timeOption)
  
  const getContourResult=(input)=>{
    setLoading(true)
    let params={}
  let tradeData=""
  if(input?.trade===null &&props.chartType==='qualitativeContourHighTemp'){
    params={
      name:"DP_qual_HT1",
      pol:`${input?.polymer}`,
      exp:`${input?.exposure}`,
      // conc:`${input?.conc}`,
      source:`${input?.report}`,
       srctype:`${filteredPosts[0]['Source Type']}`
    }
  }
  else if(input?.trade &&props.chartType==='qualitativeContourHighTemp'){
    params={
      name:"DP_qual_HT1",
      pol:`${input?.polymer}`,
      trade:`${input?.trade}`,
      exp:`${input?.exposure}`,
      // conc:`${input?.conc}`,
      source:`${input?.report}`,
      srctype:`${filteredPosts[0]['Source Type']}`
      
    }
  }
  else if( input?.trade!=='No Data' && props.chartType==='qualitativeContour') {
    params={
      name:"DP_qual",
      trade:`${input?.trade}`,
      pol:`${input?.polymer}`,
      exp:`${input?.exposure}`,
      source:`${input?.report}`
    }
  }
  else if(input?.trade==='No Data' && props.chartType==='qualitativeContour'){
    params={
      name:"DP_qual",
      pol:`${input?.polymer}`,
      exp:`${input?.exposure}`,
      source:`${input?.report}`
    }
  }
   

    // if(props.chartType==='qualitativeContourHighTemp'){
    //   params={
    //     name:" DP_qual_HT1",
    //     trade:`${tradeData}`,
    //     pol:`${input?.polymer}`,
    //     exp:`${input?.exposure}`,
    //     conc:`${input?.conc}`,
    //     source:`${input?.report}`,
    //   }
    // }
  
  const token = localStorage.getItem('accessToken');

    let URL='https://digipol.azurewebsites.net/get_matrix'
    axios({
      method:"GET",
      url:URL,
         headers: {
        Authorization: `Bearer ${token}`,
    },
      params:props.chartType==='qualitativeContour' || props.chartType==='qualitativeContourHighTemp'?
        params
      :""
    })
    .then(res=>{
   
        setLoading(false)
        setFlag(true)
        console.log("contour",res?.data)
        setContourData(res?.data)
        // setDpData(res?.data)
      
     
      // setFilteredPosts(res?.data?.data)
    })
  }

  const getDataMaterialsCO2=(input)=>{
    let params={}
      // if(input.time){
        params={
          name:'DP_CCS_08qual',
          mat:input.polymer,
          trade:input.trade,
          sup:input.polymerSupplier,
          exp:input.exposure,
          class:input.exposureClass,
          time:input.time ||'nan'
        }
      // }
  const token = localStorage.getItem('accessToken');
      
      let URL='https://digipol.azurewebsites.net/get_matrix_co2'
      axios({
      method:"GET",
      url:URL,
         headers: {
        Authorization: `Bearer ${token}`,
    },
      params:params
     
    })
    .then(res=>{
   
        setLoading(false)
        setFlag(true)
        console.log("contour",res?.data)
        setContourData(res?.data)
        // setDpData(res?.data)
      
     
      // setFilteredPosts(res?.data?.data)
    })
  }

  const getDataImpurityOnly=(input)=>{
    let params={}
   
        params={
          name:'DP_CCS_01qual',
          pol:input.polymer || 'nan',
          trade:input.trade==="No Data"?'nan':trade,
          imp:input.chemical || 'nan',
          source:input.report || 'nan',
          
        }
  const token = localStorage.getItem('accessToken');
      
      let URL='https://digipol.azurewebsites.net/get_matrix_impurity'
      axios({
      method:"GET",
      url:URL,
        headers: {
        Authorization: `Bearer ${token}`,
    },
      params:params
     
    })
    .then(res=>{
   
        setLoading(false)
        setFlag(true)
        console.log("contour",res?.data)
        setContourData(res?.data)
        // setDpData(res?.data)
      
     
      // setFilteredPosts(res?.data?.data)
    })
  }
console.log('props',props.chartType==='qualitativeContour')
  const handleSubmit=()=>{
setLoading(true)
setFlag(false)
    if(props.chartType==='chemicalCompatibilitySelectExposure'){
      if(exposure.length>0 && conc.length>0 && temp.length>0)
      console.log("call")
      setFlag(true)
      setLoading(false)
      setAxisInput({x:getValues("Trade name"),y:getValues("Volume change (%)")})
      setWeight(getValues("Weight change (%)"))
      setDensity(getValues("Density change (%)"))
    }
    else if(props.chartType==='volChangeTime'){
      // if(polymer.length>0&&trade?.length>0&&exposure.length>0&&conc.length>0){
      if(polymer.length>0){

        setFlag(true)
        setLoading(false)
        setAxisInput({x:getValues("Duration of exposure (h)"),y:getValues("Volume change (%)")})
      }
    }
    else if(props.chartType==='volChangePressure'){
      if(polymer.length>0&&exposure.length>0&&conc.length>0){
        setFlag(true)
        setLoading(false)
        setAxisInput({x:getValues("Pressure (bar)"),y:getValues("Volume change (%)")})
      }
    }
    else if(props.chartType==='dataConsolv'){
      // if(polymer.length>0&&exposure.length>0&&conc.length>0){
        let data=[]
       let result = filteredPosts.reduce(function (r, a) {
          r[a['Temperature (°C)']] = r[a['Temperature (°C)']] || [];
          r[a['Temperature (°C)']].push(a);
          return r;
      }, Object.create(null));
      for (const key of Object.keys(result)) {
        data.push(result[key])
      }


      setDataConsolvFilteredData(data)
    // console.log("result",data)
  // console.log('result',result.length);
        setFlag(true)
        setLoading(false)
        setAxisInput({x:getValues("Exposure time (day)"),y:getValues("Value"),yLabel:properties[0],temp:getValues("Temperature (°C)"),z:properties})
      // }
      console.log("temperature",getValues("Temperature (°C)"))
    }
    else if(props.chartType==='volChangeTemp'){
      // if(polymer.length>0&&trade?.length>0&&exposure.length>0&&conc.length>0){
      if(polymer.length>0){

        setFlag(true)
        setLoading(false)
        setAxisInput({x:getValues("Temperature of medium (C)"),y:getValues("Volume change (%)")})
      }
    }
    else if(props.chartType==='DataImpurityCO2'){
      setFlag(true)
      setLoading(false)
      setAxisInput({x:getValues("Chemical Component"),y:getValues("Volume change (%)")})
    }
    else if(props.chartType==='dataImpurityOnly'){
      let temp=getValues("Resistance")?.sort((a,b)=>a-b)
      getDataImpurityOnly(inputData)
      setAxisInput({x:getValues("Concentration (mol%)"),y:getValues("Temperature (C)")})
      setResistance(temp)
      
      // setAxisInput({x:getValues("Chemical Component"),y:getValues("Volume change (%)")})
    }
    else if(props.chartType==='dataMaterialsCO2'){
     
      // setLoading(false)
      // setAxisInput({x:getValues("Chemical Component"),y:getValues("Volume change (%)")})
      let temp=getValues("Resistance")?.sort((a,b)=>a-b)
      getDataMaterialsCO2(inputData)
      setAxisInput({x:getValues("Concentration (%)"),y:getValues("Temperature (C)")})
      setResistance(temp)
      
      // setAxisInput({x:getValues("Concentration (vol%)"),y:getValues("Temperature (C)")})
      // setResistance(temp)
    }
    else if(props.chartType==='qualitativeContour' || props.chartType==='qualitativeContourHighTemp' ){
      // setFlag(true)
      setLoading(false)
      // setAxisInput({x:getValues("Chemical Component"),y:getValues("Volume change (%)")})
      let temp=getValues("Resistance")?.sort((a,b)=>a-b)
      getContourResult(inputData)
      setAxisInput({x:getValues("Concentration (vol%)"),y:getValues("Temperature (C)")})
      setResistance(temp)
      
    }
    else if(props.chartType==='openDataORings'){
      setFlag(true)
      setLoading(false)
      // setAxisInput({x:getValues("Chemical Component"),y:getValues("Volume change (%)")})
      setMultipleInput([{x:null,y:getValues("Average weight change (%)"),axis:"Average Weight Change(%)",chartTitle:"Chemical compatibility of polymer",legend1:"Average weight change (%)"},{
        x:null,y:getValues("Average volume swell (%)"),axis:"Average volume swell (%)",chartTitle:"Chemical compatibility of polymer",legend1:"Average volume swell"},
        {x:null,y:getValues("Average hardness change (%)"),axis:"Average hardness change (%)",chartTitle:"Mechanical compatibilty of polymer",legend1:"Average hardness change (%)"},
        {x:null,y:getValues("Change in elongation at break (%)"),axis:"Change in elongation at break (%)",chartTitle:"Mechanical compatibilty of polymer",legend1:"Change in elongation at break (%)"}])
    }
    else if(props.chartType==='quantitativesealsco2'){
      setFlag(true)
      setLoading(false)
      let data=[]
      let result = filteredPosts?.reduce(function (r, a) {
        r[a['CO2 Phase']] = r[a['CO2 Phase']] || [];
        r[a['CO2 Phase']].push(a);
         return r;
     }, Object.create(null));
     for (const key of Object.keys(result)) {
       data.push(result[key])
     }
     console.log("result",result['Control group'])
     setDataConsolvFilteredData(data)
      // setAxisInput({x:getValues("Chemical Component"),y:getValues("Volume change (%)")})
      setMultipleInput([
        {
          xaxis:"Load (kN)",yaxis:"Rate of weight change (%)",chartTitle:"Weight change rate of polymer before & after CO2 corrosion under compressive state",phase:getValues("CO2 Phase"),
      x1:getValuesDynamic("Load (kN)",result['Control group']),y1:getValuesDynamic("Rate of weight change (%)",result['Control group']),
      x2:getValuesDynamic("Load (kN)",result['Gas']),y2:getValuesDynamic("Rate of weight change (%)",result['Gas']),
      x3:getValuesDynamic("Load (kN)",result['Liquid']),y3:getValuesDynamic("Rate of weight change (%)",result['Liquid']),
      x4:getValuesDynamic("Load (kN)",result['Initial state']),
      y4:getValuesDynamic("Rate of weight change (%)",result['Initial state'])
      },
        {
          xaxis:"Load (kN)",yaxis:"Compression set (%)",chartTitle:"Compression set of polymer before & after CO2 corrosion under compressive state",phase:getValues("CO2 Phase"),
      x1:getValuesDynamic("Load (kN)",result['Control group']),y1:getValuesDynamic("Compression set (%)",result['Control group']),
      x2:getValuesDynamic("Load (kN)",result['Gas']),y2:getValuesDynamic("Compression set (%)",result['Gas']),
      x3:getValuesDynamic("Load (kN)",result['Liquid']),y3:getValuesDynamic("Compression set (%)",result['Liquid']),
      x4:getValuesDynamic("Load (kN)",result['Initial state']),y4:getValuesDynamic("Compression set (%)",result['Initial state'])
      },
        {
          xaxis:"Load (kN)",yaxis:"Hardness (HA)",chartTitle:"Hardness comparison of polymer before & after CO2 corrosion under compressive state",phase:getValues("CO2 Phase"),
      x1:getValuesDynamic("Load (kN)",result['Control group']),y1:getValuesDynamic("Hardness (HA)",result['Control group']),
      x2:getValuesDynamic("Load (kN)",result['Gas']),y2:getValuesDynamic("Hardness (HA)",result['Gas']),
      x3:getValuesDynamic("Load (kN)",result['Liquid']),y3:getValuesDynamic("Hardness (HA)",result['Liquid']),
      x4:getValuesDynamic("Load (kN)",result['Initial state']),y4:getValuesDynamic("Hardness (HA)",result['Initial state'])
      },
      // {x:getValues("Load (kN)"),y:getValues("Compression time (h)"),axis:"Compression set (%)",chartTitle:"Compression set of polymer before & after CO2 corrosion under compressive state",phase:getValues("CO2 Phase")},
      // {x:getValues("Load (kN)"),y:getValues("Hardness (HA)"),axis:"Hardness (HA)",chartTitle:"Hardness comparison of polymer before & after CO2 corrosion under compressive state",phase:getValues("CO2 Phase")}
    ])

    }

    else if(props.chartType==='quantitativeData'){
      setFlag(true)
      setLoading(false)
      // setAxisInput({x:getValues("Chemical Component"),y:getValues("Volume change (%)")})
      setMultipleInput([{x:null,y:getValues("Weight change (%)"),y1:getValues("Density change (%)"),legend1:"Weight change (%)",legend2:"Density change (%)",axis:"Change (%)",chartTitle:"Weight and density change of polymer "},
      {x:null,y:getValues("Elongation at break (%)"),axis:"Elongation at break (%)",chartTitle:"Elongation at break of polymer",legend1:"Elongation at break (%)"},
      {x:null,y:getValues("Compression set (%)"),axis:"Compression set (%)",chartTitle:"Compression set of polymer",legend1:"Compression"},
      {x:null,y:getValues("Tensile strength at yield (psi)"),axis:"Tensile strength at yield (bar)",chartTitle:"Yield strength of polymer",legend1:"Tensile strength at yield (psi)"}])

    }
    else {

      if(polymer.length>0&&conc.length>0&&temp.length>0){
        if((timeOption?.length>0 && time?.length>0) || (pressureOption?.length>0 && pressure?.length>0)){
          setFlag(true)
          setLoading(false)
          setConcenteration(getValues("Volume change (%)"))
          setTemperature(getValues("Exposure medium"))
          setMechnicalCompatibility({elongation:getValues("Change in elongation at break (%)"),tensile:getValues("Change in tensile strength (%)"),hardness:getValues("Change in hardness (IRHD)")})
         setWeight(getValues("Weight change (%)"))
         setDensity(getValues("Density change (%)"))
          setResistance(getValues("Volume change (%)"))
        }     
      }
    }
  }
  console.log("multipleInput",multipleInput)
  const handlerReset=()=>{
    setFlag(false)
  let temp=[...inputFieldsData]
    let tempInput=inputData
    temp.forEach(function(v,j) {
      v.value = null;
    })
    Object.keys(tempInput).forEach(key => tempInput[key]=null);

  setInputFieldsData(temp)
  setInputData(tempInput)

  }
  
   // step 4
  const getTradeNameValues=(label)=>{
    console.log("calling trade")
    var filtered = DpData?.filter(function(item) {
      return item[`${label}`]==polymer;
  });
    let permittedValues = filtered?.map(value => value[`Trade name`]);
    const newArr = permittedValues?.map(item => typeof(item) == "undefined"? 'NO DATA' : item);
    let uniqueArray = newArr?.filter(function(item, pos) {
      return permittedValues?.indexOf(item) === pos;
    })
  return uniqueArray
  }

  const getExposureClass=(label)=>{
    let filtered
    if(trade==null){

      filtered=DpData?.filter(function(item) {
        return polymer?.indexOf(item['Polymer']) !== -1 && polymerSupplier&&polymerSupplier?.indexOf(item['Supplier']) !== -1 && exposure&&exposure?.indexOf(item['Exposure medium']) !== -1 ;
    });
    }
    else{

      filtered=DpData?.filter(function(item) {
        return polymer?.indexOf(item['Polymer']) !== -1 && trade&&trade?.indexOf(item['Trade name']) !== -1 && polymerSupplier&&polymerSupplier?.indexOf(item['Supplier']) !== -1 && exposure&&exposure?.indexOf(item['Exposure medium']) !== -1 ;
    });
    }
  let permittedValues = filtered?.map(value => value[`${label}`]);
  let uniqueArray = permittedValues?.filter(function(item, pos) {
    return permittedValues?.indexOf(item) == pos;
})
return uniqueArray
  }
 
 
  const getExposureMedium=(label)=>{
    let filtered = []
    if(props.chartType==='volChangeTime' || props.chartType==='volChangeTemp' || props.chartType==='dataConsolv' ||props.chartType==='qualitativeContour' ||props.chartType==='qualitativeContourHighTemp'){
      if((props.chartType==='qualitativeContour' || props.chartType==='qualitativeContourHighTemp') && inputData.trade===null){
        filtered=DpData?.filter(function(item) {
          return polymer&&polymer?.indexOf(item['Polymer']) !== -1 ;
      });
      }
      else{

        filtered=DpData?.filter(function(item) {
          return polymer&&polymer?.indexOf(item['Polymer']) !== -1 && trade&&trade?.indexOf(item['Trade name']) !== -1  ;
      });
      }
    }
    else if(props.chartType==='volChangePressure'){
      filtered=DpData?.filter(function(item) {
        return polymer&&polymer?.indexOf(item['Polymer']) !== -1  ;
    });
    }
    else if(props.chartType==='dataMaterialsCO2'){
      if(trade==null){
        filtered=DpData?.filter(function(item) {
          return polymer&&polymer?.indexOf(item['Polymer']) !== -1  && polymerSupplier&&polymerSupplier?.indexOf(item['Supplier']) !== -1 
      });
      }
      else{

        filtered=DpData?.filter(function(item) {
          return polymer&&polymer?.indexOf(item['Polymer']) !== -1 && trade&&trade?.indexOf(item['Trade name']) !== -1 && polymerSupplier&&polymerSupplier?.indexOf(item['Supplier']) !== -1 
      });
      }
    }
 
    else if(props.chartType==='chemicalCompatibilitySelectExposure'){
      filtered=[...DpData]
    }
   
    let permittedValues = filtered?.map(value => value[`${label}`]);
  let uniqueArray = permittedValues?.filter(function(item, pos) {
    return permittedValues?.indexOf(item) == pos;
})
return uniqueArray
  }


  const getSolvent=(label)=>{
    let filtered = []
    
      filtered=DpData?.filter(function(item) {
        return polymer?.indexOf(item['Polymer']) !== -1 && trade&&trade?.indexOf(item['Trade name']) !== -1 && polymerSupplier&&polymerSupplier?.indexOf(item['Supplier']) !== -1   ;
    });
    
   
    let permittedValues = filtered?.map(value => value[`${label}`]);
  let uniqueArray = permittedValues?.filter(function(item, pos) {
    return permittedValues?.indexOf(item) == pos;
})
return uniqueArray
  }
  const getProperties=(label)=>{
    let filtered = []
    
      filtered=DpData?.filter(function(item) {
        return polymer?.indexOf(item['Polymer']) !== -1 && trade&&trade?.indexOf(item['Trade name']) !== -1 && polymerSupplier&&polymerSupplier?.indexOf(item['Supplier']) !== -1 && solvent&&solvent?.indexOf(item['Solvent']) !== -1   ;
    });
    
   
    let permittedValues = filtered?.map(value => value[`${label}`]);
  let uniqueArray = permittedValues?.filter(function(item, pos) {
    return permittedValues?.indexOf(item) == pos;
})
return uniqueArray
  }

console.log("Resistance",resistance)
  const getConceteration=(label)=>{
    let filtered = []
    let data=[...DpData]
   
    if(props.chartType==='chemicalCompatibilitySelectExposure'){
      filtered=data?.filter(function(item) {
        return exposure&&exposure?.indexOf(item['Exposure medium']) !== -1   ;
    });
    }
    else if(props.chartType==='volChangeTime' || props.chartType==='volChangeTemp' || props.chartType==='volChangePressure' || props.chartType==='qualitativeContourHighTemp'){
      if(props.chartType==='qualitativeContourHighTemp' && inputData.trade==='No Data'){
        filtered=data?.filter(function(item) {
          return polymer?.indexOf(item['Polymer']) !== -1  && exposure?.indexOf(item['Exposure medium']) !== -1 ;
        });
      }
      else{
        filtered=data?.filter(function(item) {
          return polymer?.indexOf(item['Polymer']) !== -1 && trade?.indexOf(item['Trade name']) !== -1 && exposure?.indexOf(item['Exposure medium']) !== -1 ;
        });
      }
      
    
    }
    else{
      if(trade==null){
        filtered=data?.filter(function(item) {
          return polymer?.indexOf(item['Polymer']) !== -1  ;
      });      
      }
      else{

        filtered=data?.filter(function(item) {
          return polymer?.indexOf(item['Polymer']) !== -1 && trade&&trade?.indexOf(item['Trade name']) !== -1  ;
      });
      }
    }
    let permittedValues = filtered?.map(value => value[`${label}`]);
    console.log("permittedValues",permittedValues)
  let uniqueArray = permittedValues?.filter(function(item, pos) {
    return permittedValues?.indexOf(item) == pos;
})
return uniqueArray
  }
  const getChemicalImpurity=(label)=>{
    let filtered = []
   
    if(props.chartType==='DataImpurityCO2'){
      filtered=DpData?.filter(function(item) {
        return polymer?.indexOf(item['Polymer']) !== -1 ;
    });
    }
    else if (props.chartType==='dataImpurityOnly'){
      filtered=DpData?.filter(function(item) {
        return polymer?.indexOf(item['Polymer']) !== -1 && trade?.indexOf(item['Trade name']) !== -1
    });
    }
    
    let permittedValues = filtered?.map(value => value[`${label}`]);
    console.log("permittedValues",permittedValues)
  let uniqueArray = permittedValues?.filter(function(item, pos) {
    return permittedValues?.indexOf(item) == pos;
})
return uniqueArray
  }
  const getRelativeMix=(label)=>{
    let filtered = []
   
    if(props.chartType==='DataImpurityCO2'){
      filtered=DpData?.filter(function(item) {
        return polymer?.indexOf(item['Polymer']) !== -1 && trade?.indexOf(item['Trade name']) !== -1 && chemical?.indexOf(item['Chemical Component']) !== -1 && pressure&&pressure?.indexOf(item['Pressure (bar)']) !== -1 
    });
    }
    
    let permittedValues = filtered?.map(value => value[`${label}`]);
    console.log("permittedValues",permittedValues)
  let uniqueArray = permittedValues?.filter(function(item, pos) {
    return permittedValues?.indexOf(item) == pos;
})
return uniqueArray
  }
  const getSourceReport=(label)=>{
    let filtered = []
   
    if(props.chartType==='DataImpurityCO2'){
      filtered=DpData?.filter(function(item) {
        return polymer?.indexOf(item['Polymer']) !== -1 && trade?.indexOf(item['Trade name']) !== -1 && chemical?.indexOf(item['Chemical Component']) !== -1 && pressure&&pressure?.indexOf(item['Pressure (bar)']) !== -1 && relative?.indexOf(item['Relative mix (%)']) !== -1 
    });
    }
    else if(props.chartType==='qualitativeContour'){
      filtered=DpData?.filter(function(item) {
        return polymer?.indexOf(item['Polymer']) !== -1 && trade?.indexOf(item['Trade name']) !== -1 && exposure?.indexOf(item['Exposure medium']) !== -1
    });
    }
    else if(props.chartType==='dataImpurityOnly'){
      filtered=DpData?.filter(function(item) {
        return polymer?.indexOf(item['Polymer']) !== -1 && trade?.indexOf(item['Trade name']) !== -1 && exposure?.indexOf(item['Exposure medium']) !== -1 && chemical?.indexOf(item['Chemical Component']) !== -1
    });
    }
    else if(props.chartType==='qualitativeContourHighTemp'){
      filtered=DpData?.filter(function(item) {
        return polymer&&polymer?.indexOf(item['Polymer']) !== -1 && trade?.indexOf(item['Trade name']) !== -1 && exposure&&exposure?.indexOf(item['Exposure medium']) !== -1 
    });
    }
    
    let permittedValues = filtered?.map(value => value[`${label}`]);
    console.log("permittedValues1",permittedValues)
  let uniqueArray = permittedValues?.filter(function(item, pos) {
    return permittedValues?.indexOf(item) == pos;
})
return uniqueArray
  }
  const getTemperature=(label)=>{
    let filtered = []
    if(props.chartType==='chemicalCompatibilitySelectExposure'){
      filtered=DpData?.filter(function(item) {
        return exposure&& exposure.indexOf(item['Exposure medium']) !== -1  && conc&&conc.indexOf(item['Concentration of medium (vol%)']) !== -1  ;
    });
    }
    else if(props.chartType==='volChangeTime'  || props.chartType==='volChangePressure'){
      filtered=DpData?.filter(function(item) {
        return polymer?.indexOf(item['Polymer']) !== -1 && trade?.indexOf(item['Trade name']) !== -1 && exposure?.indexOf(item['Exposure medium']) !== -1 &&conc&&conc?.indexOf(item['Concentration of medium (vol%)']) !== -1 ;
      });
    }
    else if(props.chartType==='openDataORings'){
      filtered=DpData?.filter(function(item) {
        return polymer?.indexOf(item['Polymer']) !== -1 && material?.indexOf(item['Material Specification']) !== -1 && pressure&&pressure?.indexOf(item['Pressure (bar)']) !== -1
      });
    }
    else if(props.chartType==='quantitativeData'){
      filtered=DpData?.filter(function(item) {
        return polymer?.indexOf(item['Polymer']) !== -1 && trade?.indexOf(item['Trade name']) !== -1 && polymerThickness?.indexOf(item['Thickness (mm)']) !== -1 && pressure&&pressure?.indexOf(item['Pressure (bar)']) !== -1 
      });
    }
    else{
      if(trade==null){
        filtered=DpData?.filter(function(item) {
          return polymer&& polymer?.indexOf(item['Polymer']) !== -1 && conc&&conc?.indexOf(item['Concentration of medium (vol%)']) !== -1  ;
      });
      }
      else{

        filtered=DpData?.filter(function(item) {
          return polymer&& polymer?.indexOf(item['Polymer']) !== -1 && trade && trade?.indexOf(item['Trade name']) !== -1 && conc&&conc?.indexOf(item['Concentration of medium (vol%)']) !== -1  ;
      });
      }
    }
    let permittedValues = filtered?.map(value => value[`${label}`]);
    console.log("permittedValues",permittedValues)
  let uniqueArray = permittedValues?.filter(function(item, pos) {
    return permittedValues?.indexOf(item) == pos;
})
return uniqueArray
  }
  console.log("temperatureOption",temperatureOption)
  const getExposureTime=(label)=>{
    let filtered = []
    if(props.chartType==='chemicalCompatibilitySelectExposure'){
      filtered=DpData?.filter(function(item) {
        return exposure&& exposure.indexOf(item['Exposure medium']) !== -1  &&conc&& conc.indexOf(item['Concentration of medium (vol%)']) !== -1 
              && temp&& temp.indexOf(item['Temperature of medium (C)']) !== -1

    });
    }
    else if(props.chartType==='volChangeTemp'){
      filtered=DpData?.filter(function(item) {
        return polymer&&polymer?.indexOf(item['Polymer']) !== -1 && trade?.indexOf(item['Trade name']) !== -1 && exposure&&exposure?.indexOf(item['Exposure medium']) !== -1 &&  conc&&conc?.indexOf(item['Concentration of medium (vol%)']) !== -1 
      });
    }
    else if(props.chartType==='volChangeTemp' || props.chartType==='dataMaterialsCO2'){
      if(trade==null){
        
        filtered=DpData?.filter(function(item) {
          return polymer&&polymer?.indexOf(item['Polymer']) !== -1 && polymerSupplier&&polymerSupplier?.indexOf(item['Supplier']) !== -1 && exposure&&exposure?.indexOf(item['Exposure medium']) !== -1 && exposureClass&&exposureClass?.indexOf(item['Exposure medium class']) !== -1 ;
        });
      }
      else{

        filtered=DpData?.filter(function(item) {
          return polymer?.indexOf(item['Polymer']) !== -1 && trade&&trade?.indexOf(item['Trade name']) !== -1 && polymerSupplier&&polymerSupplier?.indexOf(item['Supplier']) !== -1 && exposure&&exposure?.indexOf(item['Exposure medium']) !== -1 && exposureClass&&exposureClass?.indexOf(item['Exposure medium class']) !== -1 ;
        });
      }
    }
    else if(props.chartType==='quantitativeData'){
      filtered=DpData?.filter(function(item) {
        return  polymer?.indexOf(item['Polymer']) !== -1 && trade?.indexOf(item['Trade name']) !== -1 && polymerThickness&&polymerThickness?.indexOf(item['Thickness (mm)']) !== -1 && pressure&&pressure?.indexOf(item['Pressure (bar)']) !== -1 && temp&&temp?.indexOf(item['Temperature (C)']) !== -1 && decompressionTime&&decompressionTime?.indexOf(item['Decompression time (h)'])!== -1 
      });
    }
    else{
      if(trade==null){
        filtered=DpData?.filter(function(item) {
          return polymer&& polymer.indexOf(item['Polymer']) !== -1 &&conc&& conc.indexOf(item['Concentration of medium (vol%)']) !== -1 
          && temp&& temp.indexOf(item['Temperature of medium (C)']) !== -1
      });
      }
      else{

        filtered=DpData?.filter(function(item) {
          return polymer&& polymer.indexOf(item['Polymer']) !== -1 && trade&&trade.indexOf(item['Trade name']) !== -1 &&conc&& conc.indexOf(item['Concentration of medium (vol%)']) !== -1 
          && temp&& temp.indexOf(item['Temperature of medium (C)']) !== -1
      });
      }
    }
  
    let permittedValues = filtered?.map(value => value[`${label}`]);
    const newArr = permittedValues?.map(item => typeof(item) == "undefined"? 'NO DATA' : item);
  let uniqueArray = newArr?.filter(function(item, pos) {
    return permittedValues?.indexOf(item) == pos;
})
return uniqueArray
  }
  const getExposurePressure=(label)=>{
    let filtered = []
    if(props.chartType==='chemicalCompatibilitySelectExposure'){
      filtered=DpData?.filter(function(item) {
        return exposure&& exposure.indexOf(item['Exposure medium']) !== -1  &&conc&& conc.indexOf(item['Concentration of medium (vol%)']) !== -1 
      &&temp&& temp.indexOf(item['Temperature of medium (C)']) !== -1
    });
    }
    else if(props.chartType==='volChangeTemp'){
      filtered=DpData?.filter(item => {
        // Check if Polymer and Trade name match
        const matchesPolymer = polymer?.indexOf(item['Polymer']) !== -1;
        const matchesTrade = trade?.indexOf(item['Trade name']) !== -1;
        const matchesExposure = exposure?.indexOf(item['Exposure medium']) !== -1;
        const matchesConc = conc?.indexOf(item['Concentration of medium (vol%)']) !== -1;
        
        // Apply time condition only if time is not empty
        const matchesTime = time ? time?.indexOf(item['Duration of exposure (h)']) !== -1 : true;
    
        // Return true if all conditions are met
        return matchesPolymer && matchesTrade && matchesExposure && matchesConc && matchesTime;
      });
    }
    else if(props.chartType==='DataImpurityCO2'){
      filtered=DpData?.filter(function(item) {
        return polymer?.indexOf(item['Polymer']) !== -1 && trade?.indexOf(item['Trade name']) !== -1 && chemical&&chemical?.indexOf(item['Chemical Component']) !== -1 
      });
    }
    else if(props.chartType==='openDataORings'){
      filtered=DpData?.filter(function(item) {
        return polymer?.indexOf(item['Polymer']) !== -1 && material&&material?.indexOf(item['Material Specification']) !== -1 
      });
    }
    else if(props.chartType==='quantitativeData'){
      filtered=DpData?.filter(function(item) {
        return polymer?.indexOf(item['Polymer']) !== -1 && trade?.indexOf(item['Trade name']) !== -1 && polymerThickness&&polymerThickness?.indexOf(item['Thickness (mm)']) !== -1
      });
    }
    else{
      if(trade==null){
        filtered=DpData?.filter(function(item) {
          return polymer&& polymer.indexOf(item['Polymer']) !== -1  &&conc&& conc.indexOf(item['Concentration of medium (vol%)']) !== -1 
          &&temp&& temp.indexOf(item['Temperature of medium (C)']) !== -1
      });
      }
      else{

        filtered=DpData?.filter(function(item) {
          return polymer&& polymer.indexOf(item['Polymer']) !== -1 && trade&& trade.indexOf(item['Trade name']) !== -1 &&conc&& conc.indexOf(item['Concentration of medium (vol%)']) !== -1 
          &&temp&& temp.indexOf(item['Temperature of medium (C)']) !== -1
      });
      }
    }

    let permittedValues = filtered?.map(value => value[`${label}`]);
    const newArr = permittedValues?.map(item => typeof(item) == "undefined"? 'NO DATA' : item);
  let uniqueArray = newArr?.filter(function(item, pos) {
    return permittedValues?.indexOf(item) == pos;
})
return uniqueArray
  }
 console.log("trade",tradeOption)
  const getPolymer=(label)=>{
    let permittedValues = DpData?.map(value => value[`${label}`]);
  let uniqueArray = permittedValues?.filter(function(item, pos) {
    return permittedValues?.indexOf(item) == pos;
})
return uniqueArray
  }
  const getSupplier=(label)=>{
    let filtered = []
    if(trade==null){

      filtered=DpData?.filter(function(item) {
        return polymer?.indexOf(item['Polymer'])  ;
    });
    }
    else{

      filtered=DpData?.filter(function(item) {
        return polymer?.indexOf(item['Polymer']) !== -1 && trade&&trade?.indexOf(item['Trade name']) !== -1  ;
    });
    }
    
   
    let permittedValues = filtered?.map(value => value[`${label}`]);
  let uniqueArray = permittedValues?.filter(function(item, pos) {
    return permittedValues?.indexOf(item) == pos;
})
return uniqueArray
  }
  const getMaterial=(label)=>{
    let filtered = []
    if(props.chartType==='openDataORings' || props.chartType==='quantitativesealsco2'){
      filtered=DpData?.filter(function(item) {
        return polymer&& polymer.indexOf(item['Polymer']) !== -1 
    });
    }
    let permittedValues = filtered?.map(value => value[`${label}`]);
  let uniqueArray = permittedValues?.filter(function(item, pos) {
    return permittedValues?.indexOf(item) == pos;
})
return uniqueArray
  }
  const getAgeing=(label)=>{
    let filtered=[]
     if(props.chartType==='openDataORings'){
      filtered=DpData?.filter(function(item) {
        return polymer?.indexOf(item['Polymer']) !== -1 && material&&material?.indexOf(item['Material Specification']) !== -1 && pressure&&pressure?.indexOf(item['Pressure (bar)']) !== -1 && temp&& temp.indexOf(item['Temperature (C)']) !== -1
      });
    }
    let permittedValues = filtered?.map(value => value[`${label}`]);
  let uniqueArray = permittedValues?.filter(function(item, pos) {
    return permittedValues?.indexOf(item) == pos;
})
return uniqueArray
  }
  const getCompression=(label)=>{
    let filtered=[]
    if(props.chartType==='openDataORings'){
     filtered=DpData?.filter(function(item) {
       return polymer?.indexOf(item['Polymer']) !== -1 && material&&material?.indexOf(item['Material Specification']) !== -1 && pressure&&pressure?.indexOf(item['Pressure (bar)']) !== -1 && temp&& temp.indexOf(item['Temperature (C)']) !== -1 && ageing&&ageing?.indexOf(item['Ageing period (day)']) !== -1
     });
   }
    else if(props.chartType==='quantitativesealsco2'){
     filtered=DpData?.filter(function(item) {
       return polymer?.indexOf(item['Polymer']) !== -1 && material&&material?.indexOf(item['Material Specification']) !== -1 
     });
   }
   console.log("filtered",filtered)
    let permittedValues = filtered?.map(value => value[`${label}`]);
  let uniqueArray = permittedValues?.filter(function(item, pos) {
    return permittedValues?.indexOf(item) == pos;
})
return uniqueArray
  }
  const getCompressionTime=(label)=>{
    let filtered=[]
    if(props.chartType==='quantitativesealsco2'){
     filtered=DpData?.filter(function(item) {
       return polymer?.indexOf(item['Polymer']) !== -1 && material&&material?.indexOf(item['Material Specification']) !== -1 &&  compression&&compression?.indexOf(item['Compression (%)']) !== -1 
     });
   }
    let permittedValues = filtered?.map(value => value[`${label}`]);
  let uniqueArray = permittedValues?.filter(function(item, pos) {
    return permittedValues?.indexOf(item) == pos;
})
return uniqueArray
  }
  const getReleaseTime=(label)=>{
    let filtered=[]
    if(props.chartType==='quantitativesealsco2'){
     filtered=DpData?.filter(function(item) {
       return polymer?.indexOf(item['Polymer']) !== -1 && material?.indexOf(item['Material Specification']) !== -1 &&  compression&&compression?.indexOf(item['Compression (%)']) !== -1 && compTime&&compTime?.indexOf(item['Compression time (h)']) !== -1 
     });
   }
    let permittedValues = filtered?.map(value => value[`${label}`]);
  let uniqueArray = permittedValues?.filter(function(item, pos) {
    return permittedValues?.indexOf(item) == pos;
})
return uniqueArray
  }
  const getPolymerThickness=(label)=>{
    let filtered=[]
    if(props.chartType==='quantitativeData'){
     filtered=DpData?.filter(function(item) {
       return polymer?.indexOf(item['Polymer']) !== -1 && trade?.indexOf(item['Trade name']) !== -1 
     });
   }
    let permittedValues = filtered?.map(value => value[`${label}`]);
    let arr=permittedValues.filter(x=>x)
  let uniqueArray = arr?.filter(function(item, pos) {
    return permittedValues?.indexOf(item) == pos;
})
return uniqueArray
  }
  const getDecompressionTime=(label)=>{
    let filtered=[]
    if(props.chartType==='quantitativeData'){
     filtered=DpData?.filter(function(item) {
       return polymer?.indexOf(item['Polymer']) !== -1 && trade?.indexOf(item['Trade name']) !== -1 && polymerThickness?.indexOf(item['Thickness (mm)']) !== -1 && pressure&&pressure?.indexOf(item['Pressure (bar)']) !== -1 && temp?.indexOf(item['Temperature (C)']) !== -1
     });
   }
    let permittedValues = filtered?.map(value => value[`${label}`]);
    let arr=permittedValues?.filter(x=>x)
  let uniqueArray = arr?.filter(function(item, pos) {
    return permittedValues?.indexOf(item) == pos;
})
return uniqueArray
  }
  
  console.log("temp",temp)
  useEffect(() => {
    const result = Object.keys(Object.assign({}, DpData[0]));
      let replacedItem = result?.map(item => item == "Temperature (C)" ? "Temperature (\u00B0C)" : item=="Temperature of medium (C)"?"Temperature of medium (\u00B0C)" : item)
    setUpdatedHeader(replacedItem)
    setTableHeaders(result)
  }, [DpData])

  const filterPolymer = (arr, label, temp) => {
    console.log('arr, label, temp: ', arr, label, temp);
    // Check if `temp` is an array or a string
    if(temp){

      if (Array.isArray(temp)) {
        // When `temp` is an array, filter items where the item's label value is in the `temp` array
        return arr.filter(item => temp.map(t => t.toLowerCase()).includes(item[label].toLowerCase()));
      } else if (typeof temp === 'string') {
        // When `temp` is a string, filter items where the item's label value matches the `temp` string
        return arr.filter(item => temp.toLowerCase() === item[label].toLowerCase());
      } else {
        // If `temp` is neither an array nor a string, return an empty array or handle the error as needed
        console.warn('Invalid type for temp. Expected string or array.');
        return [];
      }
    }
  };
  
//   const filterPolymer=(arr,label,temp)=>{
//     console.log('arr,label,temp: ', arr,label,temp);
// // return arr.filter(item=>temp?.indexOf(item[`${label}`]) !== -1 )

// // if(chartType=='volChangeTemp' && label=== )
// return arr.filter(item=>temp.toLowerCase()===item[`${label}`].toLowerCase()) 
//   }
  

const handleChangePage = (event, newPage) => {
    setPage(newPage);
};

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  
  function stableSort(array) {
    const stabilizedThis = array?.sort((a, b) => a['DP ID']-b['DP ID']);  
    return stabilizedThis
    
  }
  
  // }
  const getValues=(label)=>{
    let permittedValues = filteredPosts.map(value => value[`${label}`]);
  let uniqueArray = permittedValues.filter(function(item, pos) {
    return permittedValues.indexOf(item) == pos;
})
return permittedValues
  }
  const getValuesDynamic=(label,arr)=>{
    let permittedValues = arr?.map(value => value[`${label}`]);
  let uniqueArray = permittedValues?.filter(function(item, pos) {
    return permittedValues?.indexOf(item) == pos;
})
return permittedValues
  }
console.log("filteredPosts",filteredPosts)
console.log("axisInput",axisInput)





// let tempRes=[...resistance]
let tempRes=[...resistance]
tempRes=tempRes?.map(item=>item==0?5:item)
let trace1,trace2,trace7,trace8;


useEffect(() => {
if(props.chartType==='quantitativesealsco2'){
let trace=[{

    type: 'bar',
    x: temperature,
    y: mechnicalCompatibility?.elongation,
    name: 'Change in elongation at break (%)',
    // text: concenteration.map(item=>item),
    textposition: 'auto',
    mode: 'markers',
    // customdata:resistance?.map(data=>data),
    hovertemplate:
  "%{xaxis.title.text}: %{x}<br>" +
  "%{yaxis.title.text}: %{y}<br>" +
  // "Resistance: %{customdata}" +
  "<extra></extra>",
  marker:{
    // color:filteredPosts.map(d => getColor(d["Volume change (%)"]>5?"red":"blue"))
    // opacity: 0.6,
    line: {
      // color:concenteration.map(d => d>=-5 && d<=0?"blue":d>0 && d<=10?"green": d>10 && d<=15 ?"yellow" :d>15 && d<=20?"orange" :d>20?"red":"silver"),
      color:"rgb(18,142,166)",
      width: 1.5
    },
    color:"rgb(18,142,166)"
  }
  
  }]
    
}
}, [props.chartType])


if(props.chartType==='mechnicalCompatibility'){
   trace1={
    // x: [40, 60, 80, 100],
    // y: [40, 60, 80, 100],
    type: 'bar',
    x: temperature,
    y: mechnicalCompatibility?.elongation,
    name: 'Change in elongation at break (%)',
    // text: concenteration.map(item=>item),
    textposition: 'auto',
    mode: 'markers',
    // customdata:resistance?.map(data=>data),
    hovertemplate:
  "%{xaxis.title.text}: %{x}<br>" +
  "%{yaxis.title.text}: %{y}<br>" +
  // "Resistance: %{customdata}" +
  "<extra></extra>",
  marker:{
    // color:filteredPosts.map(d => getColor(d["Volume change (%)"]>5?"red":"blue"))
    // opacity: 0.6,
    line: {
      // color:concenteration.map(d => d>=-5 && d<=0?"blue":d>0 && d<=10?"green": d>10 && d<=15 ?"yellow" :d>15 && d<=20?"orange" :d>20?"red":"silver"),
      color:"rgb(18,142,166)",
      width: 1.5
    },
    color:"rgb(18,142,166)"
  }
  
  }
   trace7={
    // x: [40, 60, 80, 100],
    // y: [40, 60, 80, 100],
    type: 'bar',
    x: temperature,
    y: mechnicalCompatibility?.tensile,
    name: 'Change in tensile strength (%)',
    // text: concenteration.map(item=>item),
    textposition: 'auto',
    mode: 'markers',
    // customdata:resistance?.map(data=>data),
    hovertemplate:
  "%{xaxis.title.text}: %{x}<br>" +
  "%{yaxis.title.text}: %{y}<br>" +
  // "Resistance: %{customdata}" +
  "<extra></extra>",
  marker:{
    // color:filteredPosts.map(d => getColor(d["Volume change (%)"]>5?"red":"blue"))
    // opacity: 0.6,
    line: {
      // color:concenteration.map(d => d>=-5 && d<=0?"blue":d>0 && d<=10?"green": d>10 && d<=15 ?"yellow" :d>15 && d<=20?"orange" :d>20?"red":"silver"),
      color:"rgb(80,11,81)",
      width: 1.5
    },
    color:"rgb(80,11,81)"
  }
  
  }
   trace8={
    // x: [40, 60, 80, 100],
    // y: [40, 60, 80, 100],
    type: 'bar',
    x: temperature,
    y: mechnicalCompatibility?.hardness,
    name: 'Change in hardness (IRHD)',
    // text: concenteration.map(item=>item),
    textposition: 'auto',
    mode: 'markers',
    // customdata:resistance?.map(data=>data),
    hovertemplate:
  "%{xaxis.title.text}: %{x}<br>" +
  "%{yaxis.title.text}: %{y}<br>" +
  // "Resistance: %{customdata}" +
  "<extra></extra>",
  marker:{
    // color:filteredPosts.map(d => getColor(d["Volume change (%)"]>5?"red":"blue"))
    // opacity: 0.6,
    line: {
      // color:concenteration.map(d => d>=-5 && d<=0?"blue":d>0 && d<=10?"green": d>10 && d<=15 ?"yellow" :d>15 && d<=20?"orange" :d>20?"red":"silver"),
      color:"rgb(177,207,18)",
      width: 1.5
    },
    color:"rgb(177,207,18)"
  }
  
  }
}
else if(props.chartType==='chemicalCompatibilitySelectExposure'){
   trace1={
    // x: [40, 60, 80, 100],
    // y: [40, 60, 80, 100],
    type: 'bar',
    x: axisInput.x,
    y: axisInput.y,
    name:`${axisInput?.y.map(d => d>=-5 && d<=0?"Shrinkage ( -5%to0%)":d>0 && d<=10?"Swelling (0% to 10% )": d>10 && d<=15 ?"Low to moderate swelling (10% to 15%)" :d>15 && d<=20?"Moderate to high swelling (15% to 20%)" :d>20?"Very high swelling (>20%)":"").filter((item, pos)=>  axisInput?.y.map(d => d>=-5 && d<=0?"Shrinkage ( -5%to0%)":d>0 && d<=10?"Swelling (0% to 10% )": d>10 && d<=15 ?"Low to moderate swelling (10% to 15%)" :d>15 && d<=20?"Moderate to high swelling (15% to 20%)" :d>20?"Very high swelling (>20%)":"").indexOf(item) == pos)}`,
    // text: concenteration.map(item=>item),
    textposition: 'auto',
    mode: 'markers',
    // customdata:resistance?.map(data=>data),
    hovertemplate:
  "%{xaxis.title.text}: %{x}<br>" +
  "%{yaxis.title.text}: %{y}<br>" +
  // "Resistance: %{customdata}" +
  "<extra></extra>",
  marker:{
    line: {
      color:axisInput?.y?.map(d => d>=-5 && d<=0?"rgb(38,63,131)":d>0 && d<=10?"rgb(15,112,7)": d>10 && d<=15 ?"rgb(255,255,9)" :d>15 && d<=20?"rgb(250,190,25)" :d>20?"rgb(193,31,4)":"silver"),
      width: 1.5
    },
    
    color:axisInput?.y?.map(d => d>=-5 && d<=0?"rgb(38,63,131)":d>0 && d<=10?"rgb(15,112,7)": d>10 && d<=15 ?"rgb(255,255,9)" :d>15 && d<=20?"rgb(250,190,25)" :d>20?"rgb(193,31,4)":"silver")
   }
  
  }
  trace7={
    // x: [40, 60, 80, 100],
    // y: [40, 60, 80, 100],
    type: 'bar',
    x: axisInput?.x,
    y: weight,
    name: 'Weight change(%)',
    // text: concenteration.map(item=>item),
    textposition: 'auto',
    mode: 'markers',
    // customdata:resistance?.map(data=>data),
    hovertemplate:
   "%{xaxis.title.text}: %{x}<br>" +
   "Weight change(%): %{y}<br>" +
   // "Resistance: %{customdata}" +
   "<extra></extra>",
   marker:{
    // color:filteredPosts.map(d => getColor(d["Volume change (%)"]>5?"red":"blue"))
    // opacity: 0.6,
    line: {
      // color:concenteration.map(d => d>=-5 && d<=0?"blue":d>0 && d<=10?"green": d>10 && d<=15 ?"yellow" :d>15 && d<=20?"orange" :d>20?"red":"silver"),
      color:"rgb(80,11,81)",
      width: 1.5
    },
    color:"rgb(80,11,81)"
   }
   
   
   }
   trace8={
    // x: [40, 60, 80, 100],
    // y: [40, 60, 80, 100],
    type: 'bar',
    x: axisInput?.x,
    y: density,
    name: 'Density change(%)',
    // text: concenteration.map(item=>item),
    textposition: 'auto',
    mode: 'markers',
    // customdata:resistance?.map(data=>data),
    hovertemplate:
   "%{xaxis.title.text}: %{x}<br>" +
   "Density change(%): %{y}<br>" +
   // "Resistance: %{customdata}" +
   "<extra></extra>",
   marker:{
    // color:filteredPosts.map(d => getColor(d["Volume change (%)"]>5?"red":"blue"))
    // opacity: 0.6,
    line: {
      // color:concenteration.map(d => d>=-5 && d<=0?"blue":d>0 && d<=10?"green": d>10 && d<=15 ?"yellow" :d>15 && d<=20?"orange" :d>20?"red":"silver"),
      color:"rgb(21,142,166)",
      width: 1.5
    },
    color:"rgb(21,142,166)"
   }
   
   }
}
else if(props.chartType==='volChangeTime'  || props.chartType==='volChangeTemp'){
  trace1={
    // x: [40, 60, 80, 100],
    // y: [40, 60, 80, 100],
    // type: 'scatter',
    x: axisInput?.x,
    y: axisInput?.y,
    showlegend:true,
    name: `Exposure medium : ${inputData?.exposure}`,
    // text: concenteration.map(item=>item),
    textposition: 'auto',
    mode:'lines+markers',
    customdata:resistance?.map(data=>data),
    hovertemplate:
   "%{xaxis.title.text}: %{x}<br>" +
   "%{yaxis.title.text}: %{y}<br>" +
   // "Resistance: %{customdata}" +
   "<extra></extra>",
   marker:{
    // color:filteredPosts.map(d => getColor(d["Volume change (%)"]>5?"red":"blue"))
    // opacity: 0.6,
    line: {
      // color:concenteration.map(d => d>=-5 && d<=0?"blue":d>0 && d<=10?"green": d>10 && d<=15 ?"yellow" :d>15 && d<=20?"orange" :d>20?"red":"silver"),
      color:"rgb(80,11,81)",
      width: 1.5
    },
    color:"rgb(80,11,81)"
   }
   
   }
    trace7={
    // type: 'scatter',
   name:"Swelling (0% to 10% )",
   // legendgroup:"a",
   x:[],
   y:[],
   showlegend:true,
   marker:{
    color:"rgb(15,112,7)"
    // color:concenteration.map(d => d>=-5 && d<=0?"rgb(38,63,131)":d>0 && d<=10?"rgb(15,112,7)": d>10 && d<=15 ?"rgb(255,255,9)" :d>15 && d<=20?"rgb(250,190,25)" :d>20?"rgb(193,31,4)":"silver")
   }
   }
  // Assuming your maximum x value is 20, for example
  const maxXValue = axisInput?.x && axisInput.x.length > 0 ? Math.max(...axisInput.x) : 0;


trace8 = {
    type: 'scatter',
    name: "Swelling (10% Shell allowable limit)",
    mode: 'lines',
    x: [0, maxXValue], // Extend to the max x value
    y: [10, 10], // Horizontal line at y = 10
    showlegend: true,
    line: {
        color: "red", // Line color
        width: 2 // Line width
    }
};

}
else if(props.chartType==='dataConsolv' ){

  trace1={
    // x: [40, 60, 80, 100],
    // y: [40, 60, 80, 100],
    type: 'scatter',
    x: getValuesDynamic('Exposure time (day)',dataConsolvFilteredData[0]),
    y: getValuesDynamic('Value',dataConsolvFilteredData[0]),
    showlegend:true,
    name: `Temperature : ${axisInput?.temp?.filter(function(item, pos, self) {
      return self.indexOf(item) == pos;
  })[0]}`,
    // text: concenteration.map(item=>item),
    textposition: 'auto',
    mode: 'lines+markers',
    customdata:resistance?.map(data=>data),
    hovertemplate:
   "%{xaxis.title.text}: %{x}<br>" +
   "%{yaxis.title.text}: %{y}<br>" +
   // "Resistance: %{customdata}" +
   "<extra></extra>",
   marker:{
    color:"blue",
    // opacity: 0.6,
    line: {
      // color:concenteration.map(d => d>=-5 && d<=0?"blue":d>0 && d<=10?"green": d>10 && d<=15 ?"yellow" :d>15 && d<=20?"orange" :d>20?"red":"silver"),
      color:"blue",
      width: 1.5
    },
    color:"blue"
   }
   
   }
  trace7={
    // x: [40, 60, 80, 100],
    // y: [40, 60, 80, 100],
    type: 'scatter',
    x: getValuesDynamic('Exposure time (day)',dataConsolvFilteredData[1]),
    y: getValuesDynamic('Value',dataConsolvFilteredData[1]),
    showlegend:true,
    name: `Temperature : ${axisInput?.temp?.filter(function(item, pos, self) {
     return self.indexOf(item) == pos;
  })[1]}`,
    // text: concenteration.map(item=>item),
    textposition: 'auto',
    mode: 'lines+markers',
    customdata:resistance?.map(data=>data),
    hovertemplate:
   "%{xaxis.title.text}: %{x}<br>" +
   "%{yaxis.title.text}: %{y}<br>" +
   // "Resistance: %{customdata}" +
   "<extra></extra>",
   marker:{
    color:"purple",
    // opacity: 0.6,
    line: {
      // color:concenteration.map(d => d>=-5 && d<=0?"blue":d>0 && d<=10?"green": d>10 && d<=15 ?"purple" :d>15 && d<=20?"orange" :d>20?"red":"silver"),
      color:"purple",
      width: 1.5
    },
    color:"purple"
   }
   
   }
  trace8={
    // x: [40, 60, 80, 100],
    // y: [40, 60, 80, 100],
    type: 'scatter',
    x: getValuesDynamic('Exposure time (day)',dataConsolvFilteredData[2]),
    y: getValuesDynamic('Value',dataConsolvFilteredData[2]),
    showlegend:true,
    name: `Temperature : ${axisInput?.temp?.filter(function(item, pos, self) {
      return self.indexOf(item) == pos;
  })[2]}`,
    // text: concenteration.map(item=>item),
    textposition: 'auto',
    mode: 'lines+markers',
    customdata:resistance?.map(data=>data),
    hovertemplate:
   "%{xaxis.title.text}: %{x}<br>" +
   "%{yaxis.title.text}: %{y}<br>" +
   // "Resistance: %{customdata}" +
   "<extra></extra>",
   marker:{
    color:"red",
    // opacity: 0.6,
    line: {
      // color:concenteration.map(d => d>=-5 && d<=0?"blue":d>0 && d<=10?"green": d>10 && d<=15 ?"yellow" :d>15 && d<=20?"orange" :d>20?"red":"silver"),
      color:"red",
      width: 1.5
    },
    color:"red"
   }
   
   }
   
  
}
else if(props.chartType==='qualitativeContour'){
  trace1= [ {
    z: contourData,
    type: 'contour',
    colorscale: 'Jet',
    dx: 10,
    x0: 5,
    dy: 10,
    y0: 10
  }]  
    
}
else if(props.chartType==='volChangePressure'){
  trace1={
    // x: [40, 60, 80, 100],
    // y: [40, 60, 80, 100],
    x: axisInput?.x,
    y: axisInput?.y,
    showlegend:false,
    name:`${axisInput?.y.map(d => d>=-5 && d<=0?"Shrinkage ( -5%to0%)":d>0 && d<=10?"Swelling (0% to 10% )": d>10 && d<=15 ?"Low to moderate swelling (10% to 15%)" :d>15 && d<=20?"Moderate to high swelling (15% to 20%)" :d>20?"Very high swelling (>20%)":"").filter((item, pos)=>  axisInput?.y.map(d => d>=-5 && d<=0?"Shrinkage ( -5%to0%)":d>0 && d<=10?"Swelling (0% to 10% )": d>10 && d<=15 ?"Low to moderate swelling (10% to 15%)" :d>15 && d<=20?"Moderate to high swelling (15% to 20%)" :d>20?"Very high swelling (>20%)":"").indexOf(item) == pos)}`,
    // text: concenteration.map(item=>item),
    textposition: 'auto',
    mode: 'markers',
    customdata:resistance?.map(data=>data),
    hovertemplate:
   "%{xaxis.title.text}: %{x}<br>" +
   "%{yaxis.title.text}: %{y}<br>" +
   // "Resistance: %{customdata}" +
   "<extra></extra>",
   marker:{
    line: {
      color:axisInput?.y?.map(d => d>=-5 && d<=0?"rgb(38,63,131)":d>0 && d<=10?"rgb(15,112,7)": d>10 && d<=15 ?"rgb(255,255,9)" :d>15 && d<=20?"rgb(250,190,25)" :d>20?"rgb(193,31,4)":"silver"),
      width: 1.5
    },
    size:axisInput?.x?.map(x=>x<20?5:x/5),
    
    color:axisInput?.y?.map(d => d>=-5 && d<=0?"rgb(38,63,131)":d>0 && d<=10?"rgb(15,112,7)": d>10 && d<=15 ?"rgb(255,255,9)" :d>15 && d<=20?"rgb(250,190,25)" :d>20?"rgb(193,31,4)":"silver")
   }
   
   }
    trace7={
   name:"Swelling (0% to 10% )",
   // legendgroup:"a",
   x:[],
   y:[],
   showlegend:true,
   marker:{
    color:"rgb(15,112,7)"
    // color:concenteration.map(d => d>=-5 && d<=0?"rgb(38,63,131)":d>0 && d<=10?"rgb(15,112,7)": d>10 && d<=15 ?"rgb(255,255,9)" :d>15 && d<=20?"rgb(250,190,25)" :d>20?"rgb(193,31,4)":"silver")
   }
   }
    trace8={
   name:"Swelling (0% to 10% )",
   // legendgroup:"a",
   x:[],
   y:[],
   showlegend:true,
   marker:{
    color:"rgb(15,112,7)"
    // color:concenteration.map(d => d>=-5 && d<=0?"rgb(38,63,131)":d>0 && d<=10?"rgb(15,112,7)": d>10 && d<=15 ?"rgb(255,255,9)" :d>15 && d<=20?"rgb(250,190,25)" :d>20?"rgb(193,31,4)":"silver")
   }
   }
   const maxXValue = axisInput?.x && axisInput.x.length > 0 ? Math.max(...axisInput.x) : 0;
   trace2 = {
    type: 'scatter',
    name: "Swelling (10% Shell allowable limit)",
    mode: 'lines',
    x: [0, maxXValue], // Extend to the max x value
    y: [10, 10], // Horizontal line at y = 10
    showlegend: true,
    line: {
        color: "red", // Line color
        width: 2 // Line width
    }
};
}
else if(props.chartType==='DataImpurityCO2'){
  trace1={
    // x: [40, 60, 80, 100],
    // y: [40, 60, 80, 100],
    x: axisInput?.x,
    y: axisInput?.y,
    showlegend:false,
    width:0.1,
    type: 'bar',
    // name:`${axisInput?.y.map(d => d>=-5 && d<=0?"Shrinkage ( -5%to0%)":d>0 && d<=10?"Swelling (0% to 10% )": d>10 && d<=15 ?"Low to moderate swelling (10% to 15%)" :d>15 && d<=20?"Moderate to high swelling (15% to 20%)" :d>20?"Very high swelling (>20%)":"").filter((item, pos)=>  axisInput?.y.map(d => d>=-5 && d<=0?"Shrinkage ( -5%to0%)":d>0 && d<=10?"Swelling (0% to 10% )": d>10 && d<=15 ?"Low to moderate swelling (10% to 15%)" :d>15 && d<=20?"Moderate to high swelling (15% to 20%)" :d>20?"Very high swelling (>20%)":"").indexOf(item) == pos)}`,
    // text: concenteration.map(item=>item),
    textposition: 'auto',
    mode: 'markers',
    customdata:resistance?.map(data=>data),
    hovertemplate:
   "%{xaxis.title.text}: %{x}<br>" +
   "%{yaxis.title.text}: %{y}<br>" +
   // "Resistance: %{customdata}" +
   "<extra></extra>",
   marker:{
    // line: {
    //   color:axisInput?.y?.map(d => d>=-5 && d<=0?"rgb(38,63,131)":d>0 && d<=10?"rgb(15,112,7)": d>10 && d<=15 ?"rgb(255,255,9)" :d>15 && d<=20?"rgb(250,190,25)" :d>20?"rgb(193,31,4)":"silver"),
    // },
    size:1,
    
    // color:axisInput?.y?.map(d => d>=-5 && d<=0?"rgb(38,63,131)":d>0 && d<=10?"rgb(15,112,7)": d>10 && d<=15 ?"rgb(255,255,9)" :d>15 && d<=20?"rgb(250,190,25)" :d>20?"rgb(193,31,4)":"silver")
   }
   
   }
    trace7={
  //  name:"Swelling (0% to 10% )",
   // legendgroup:"a",
   x:[],
   y:[],
   showlegend:true,
   marker:{
    color:"rgb(15,112,7)"
    // color:concenteration.map(d => d>=-5 && d<=0?"rgb(38,63,131)":d>0 && d<=10?"rgb(15,112,7)": d>10 && d<=15 ?"rgb(255,255,9)" :d>15 && d<=20?"rgb(250,190,25)" :d>20?"rgb(193,31,4)":"silver")
   }
   }
    trace8={
  //  name:"Swelling (0% to 10% )",
   // legendgroup:"a",
   x:[],
   y:[],
   showlegend:true,
   marker:{
    color:"rgb(15,112,7)"
    // color:concenteration.map(d => d>=-5 && d<=0?"rgb(38,63,131)":d>0 && d<=10?"rgb(15,112,7)": d>10 && d<=15 ?"rgb(255,255,9)" :d>15 && d<=20?"rgb(250,190,25)" :d>20?"rgb(193,31,4)":"silver")
   }
   }
}
else{

  trace1={
   // legendgroup:"a",
   type: 'bar',
   x: temperature,
   y: concenteration,
   name:`${concenteration.map(d => d>=-5 && d<=0?"Shrinkage ( -5%to0%)":d>0 && d<=10?"Swelling (0% to 10% )": d>10 && d<=15 ?"Low to moderate swelling (10% to 15%)" :d>15 && d<=20?"Moderate to high swelling (15% to 20%)" :d>20?"Very high swelling (>20%)":"").filter((item, pos)=>  concenteration.map(d => d>=-5 && d<=0?"Shrinkage ( -5%to0%)":d>0 && d<=10?"Swelling (0% to 10% )": d>10 && d<=15 ?"Low to moderate swelling (10% to 15%)" :d>15 && d<=20?"Moderate to high swelling (15% to 20%)" :d>20?"Very high swelling (>20%)":"").indexOf(item) == pos)}`,

   showlegend: true,
   textposition: 'auto',
   mode: 'markers',
   customdata:resistance?.map(data=>data),
   hovertemplate:
  "%{xaxis.title.text}: %{x}<br>" +
  "%{yaxis.title.text}: %{y}<br>" +
  // "Resistance: %{customdata}" +
  "<extra></extra>",
  marker:{
   line: {
     color:concenteration.map(d => d>=-5 && d<=0?"rgb(38,63,131)":d>0 && d<=10?"rgb(15,112,7)": d>10 && d<=15 ?"rgb(255,255,9)" :d>15 && d<=20?"rgb(250,190,25)" :d>20?"rgb(193,31,4)":"silver"),
     width: 1.5
   },
   
   color:concenteration.map(d => d>=-5 && d<=0?"rgb(38,63,131)":d>0 && d<=10?"rgb(15,112,7)": d>10 && d<=15 ?"rgb(255,255,9)" :d>15 && d<=20?"rgb(250,190,25)" :d>20?"rgb(193,31,4)":"silver")
  }
  }
 
  trace7={
   type: 'bar',
   x: temperature,
   y: weight,
   name: 'Weight change(%)',
   // text: concenteration.map(item=>item),
   textposition: 'auto',
   mode: 'markers',
   customdata:resistance?.map(data=>data),
   hovertemplate:
  "%{xaxis.title.text}: %{x}<br>" +
  "Weight change(%): %{y}<br>" +
  // "Resistance: %{customdata}" +
  "<extra></extra>",
  marker:{
   // color:filteredPosts.map(d => getColor(d["Volume change (%)"]>5?"red":"blue"))
   // opacity: 0.6,
   line: {
     // color:concenteration.map(d => d>=-5 && d<=0?"blue":d>0 && d<=10?"green": d>10 && d<=15 ?"yellow" :d>15 && d<=20?"orange" :d>20?"red":"silver"),
     color:"rgb(80,11,81)",
     width: 1.5
   },
   color:"rgb(80,11,81)"
  }
  
  }
  trace8={
   type: 'bar',
   x: temperature,
   y: density,
   name: 'Density change(%)',
   // text: concenteration.map(item=>item),
   textposition: 'auto',
   mode: 'markers',
   customdata:resistance?.map(data=>data),
   hovertemplate:
  "%{xaxis.title.text}: %{x}<br>" +
  "Density change(%): %{y}<br>" +
  // "Resistance: %{customdata}" +
  "<extra></extra>",
  marker:{
   // color:filteredPosts.map(d => getColor(d["Volume change (%)"]>5?"red":"blue"))
   // opacity: 0.6,
   line: {
     // color:concenteration.map(d => d>=-5 && d<=0?"blue":d>0 && d<=10?"green": d>10 && d<=15 ?"yellow" :d>15 && d<=20?"orange" :d>20?"red":"silver"),
     color:"rgb(21,142,166)",
     width: 1.5
   },
   color:"rgb(21,142,166)"
  }
  
  }
}

useEffect(() => {
 if(props.chartType==='openDataORings'){
  setResistanceInputFiled( [
    {name:"Phase of CO2",field:"CO2 Phase"},
    {name:"Source/Author",field:"Source/Author"},        
    {name:"Source Report",field:"Source Type"},
    ])
 }
 else if(props.chartType==='quantitativesealsco2'){
  setResistanceInputFiled( [
    {name:"Application (Downhole)",field:"Application"},
    {name:"Source/Author",field:"Source/Author"},        
    {name:"Source Report",field:"Report name"},
    ])
 }
 else if(props.chartType==='quantitativeData'){
  setResistanceInputFiled( [
    {name:"Phase of CO2",field:"CO2 Phase"},
    {name:"Polymer Morphology",field:"Morphology"},
    {name:"Note/Typical Application",field:"Morphology"},
    {name:"Source Report",field:"Report name"},
    {name:"Source/Author",field:"Source/Author"},        
    ])
 }
 else if(props.chartType==='dataConsolv'){
  setResistanceInputFiled( [
    {name:"Source Type",field:"Source Type"},
    {name:"Source Report",field:"Report name"},
    {name:"Source/Author",field:"Source/Author"},        
    ])
 }
 else if(props.chartType==='dataImpurityOnly'){
  setResistanceInputFiled( [
    {name:"Note",field:"Note"},
    {name:"Chemical Resistance",field:"Qualitative result"},
    {name:"Resistance to Aldehydes",field:"Chemical Component"},        
    {name:"Application type",field:"Application"},        
    {name:"Source/Author",field:"Source/Author"},        
    ])
 }
 else if(props.chartType==='dataMaterialsCO2'){
  setResistanceInputFiled( [
    {name:"Polymer class",field:"Material class "},
    {name:"Concentration note",field:"Exposure medium note"},
    {name:"Temperature note",field:"Temperature note"},        
    {name:"Exposure medium note",field:"Exposure medium note"},        
    {name:"Exposure Pressure (bar)",field:"Pressure (bar)"},        
    {name:"Resistance note",field:"Resistance note"},        
    {name:"Source report",field:"Report name"},        
    {name:"Source/Author",field:"Source/Author"},        
    ])
 }
 else if(props.chartType==='DataImpurityCO2'){
  setResistanceInputFiled( [
    {name:"Phase of CO2",field:"CO2 Phase"},
    {name:"Temperature (\u00B0C)",field:"Temperature (C)"},
    {name:"Source/Author",field:"Source/Author"},        
    {name:"Application Type",field:"Application"},              
    ])
 }
 else{
  setResistanceInputFiled( [
    {name:"Polymer Type",field:"Polymer"},
    {name:"Source Report",field:"Report no."},
    {name:"Report Title",field:"Report name"},        
    ])
 }
}, [props.chartType])




const onInputChange=(sliderName, value) => {
  setFlag(false)


  var index = Object.keys(inputData).indexOf(`${sliderName}`);
  var tempData = inputData;
  for(var i=index;i<Object.keys(inputData)?.length-1;i++){
    tempData[Object.keys(tempData)[i+1]]=null
    }
  tempData[sliderName] = value;
  setInputData(tempData)
  setInputData({
    ...inputData,
    isChangeVal: !inputData.isChangeVal
  })
  
  let temp=[...inputFieldsData]
  temp.forEach(function(v,j) { 
    v.value = tempData[v.name];
  })
  setInputFieldsData(temp)
}

console.log("exposureOption",exposureOption)
const removeAdjacentDuplicate=(arr)=>{
  const result = arr?.filter((i,idx) => arr[idx-1] !== i)
  return result
}
console.log("inputFieldsData",inputFieldsData)

const handlePressureChange = (event, newValue) => {
  var index = Object.keys(inputData).indexOf(`pressure`);
  var tempData = inputData;
  for(var i=index;i<Object.keys(inputData)?.length-1;i++){
    tempData[Object.keys(tempData)[i+1]]=null
    }
  tempData['pressure'] = newValue;
  setInputData(tempData)
  setInputData({
    ...inputData,
    isChangeVal: !inputData.isChangeVal
  })
  setInputFieldsData(prevState => 
    prevState.map(field =>
      field.id === 'pressure'
        ? { ...field, value: newValue }
        : field
    )
  );
};
  return (
   <>
    <fieldset style={{ height: "auto", display: "flex" }}>
    <legend align="left" style={{ fontWeight: 'bold', color: 'gray' }}> Filter </legend>
   {/* <Box> */}
   <ValidatorForm
                ref={form}
                onSubmit={handleSubmit}
                onError={errors => console.log(errors)}
                noValidate={true}
            >
   <Grid container spacing={2}>
   {inputFieldsData?.map((item, i) => {
  // Conditionally render if item.id is not 'pressure'
  if (item.id == 'pressure' && props.chartType=='volChangeTemp') {
    return (
      <Grid item xs={12} md={4}>
      <FormControl className={classes.formControl}>
        <InputWithoutValidator
          value={item?.value??[]}
          options={pressureOption} // 
          onChange={handlePressureChange}
          label="Pressure"
          multiple
          loading={false} // 
        />
         {/* <Autocomplete
      multiple
      options={options}
      value={value}
      onChange={(_, newValue) => onChange(newValue)}
      disableCloseOnSelect
      getOptionLabel={(option) => option.title}
      renderOption={(props, option, { selected }) => {
        const { key, ...optionProps } = props;
        return (
          <li key={key} {...optionProps}>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={selected}
            />
            {option.title}
          </li>
        );
      }}
      renderInput={(params) => (
        <TextField {...params} label={label} placeholder="Select options" />
      )}
    /> */}
      </FormControl>
    </Grid>
    ); // Skip rendering this item
  }

  return (
    <Grid key={i} item xs={12} md={4}>
      <AutocompleteFields data={item} onInputChange={onInputChange} />
    </Grid>
  );
})}

  
      {/* )
    })} */}
         <div style={{ display:"flex",justifyContent:"center",width:"100%",marginTop:"10px" }}>
            <Button id="btnPhCalcPredict" variant="contained" color="primary" type='submit'> Submit
            </Button>
            <Button id="btnPhCalcReset" style={{ marginLeft: '20px' }} onClick={handlerReset}  variant="contained" color="primary"> Clear
            </Button>
            <br></br>

          </div>
  </Grid>
</ValidatorForm>

   {/* </Box> */}
   </fieldset>
  
   {flag ?
   <Box style={{marginTop:"10px"}}>
   <Grid container>
  <Grid item xs={12} md={12}>
{/* <Delayed> */}
<div style={{position:"relative"}}>

  {props.chartType==='selectPolymer' ||props.chartType==='chemicalCompatibilitySelectExposure'||props.chartType==='volChangePressure' ?

<div id="para">
<p style={{backgroundColor:"rgb(38,63,131)",margin:"0"}}>Shrinkage (-5% to 0%)</p>
<p style={{backgroundColor:"rgb(15,112,7)",margin:"0"}}>Swelling (0% to 10%)</p>
<p style={{backgroundColor:"rgb(255,255,9)",margin:"0",color:"black"}}>Low to moderate swelling (10% to 15%)</p>
<p style={{backgroundColor:"rgb(250,190,25)",margin:"0",color:"black"}}>Moderate to high swelling (15% to 20%)</p>
<p style={{backgroundColor:"rgb(193,31,4)",margin:"0"}}>Very high swelling ({'>'} 20%)</p>
</div>:null
}
{props.chartType==='openDataORings' || props.chartType==='quantitativeData' ?

<Grid container spacing={2} >
{multipleInput.map((item,i)=>
(
    
    <Grid item xs={6} >
    
  <div style={{border:"1px solid silver",width:"100%",height:"100%"}}>
{
  item?.y?.indexOf("") === -1 ?
<Plot  
    var data = {[
      {
      // x: [40, 60, 80, 100],
      // y: [40, 60, 80, 100],
      // x: item?.x,
      y: item?.y,
      showlegend:item?.y,
      // width:0.1,
      type: 'bar',
      // name:`${axisInput?.y.map(d => d>=-5 && d<=0?"Shrinkage ( -5%to0%)":d>0 && d<=10?"Swelling (0% to 10% )": d>10 && d<=15 ?"Low to moderate swelling (10% to 15%)" :d>15 && d<=20?"Moderate to high swelling (15% to 20%)" :d>20?"Very high swelling (>20%)":"").filter((item, pos)=>  axisInput?.y.map(d => d>=-5 && d<=0?"Shrinkage ( -5%to0%)":d>0 && d<=10?"Swelling (0% to 10% )": d>10 && d<=15 ?"Low to moderate swelling (10% to 15%)" :d>15 && d<=20?"Moderate to high swelling (15% to 20%)" :d>20?"Very high swelling (>20%)":"").indexOf(item) == pos)}`,
      // text: concenteration.map(item=>item),
      name:item?.legend1,
      textposition: 'auto',
      mode: 'markers',
      customdata:resistance?.map(data=>data),
      hovertemplate:
    //  "%{xaxis.title.text}: %{x}<br>" +
     `${item?.legend1}: %{y}<br>` +
     // "Resistance: %{customdata}" +
     "<extra></extra>",
    //  marker:{
    //   line: {
    //     color:"blue"
    //   },
    //   size:1,
      
    //   color:"blue"
    //  }
     
     },
      {
      // x: [40, 60, 80, 100],
      // y: [40, 60, 80, 100],
      // x: item?.x,
      y: item?.y1,
      showlegend:item?.y1,
      // width:0.1,
      type: 'bar',
      // name:`${axisInput?.y.map(d => d>=-5 && d<=0?"Shrinkage ( -5%to0%)":d>0 && d<=10?"Swelling (0% to 10% )": d>10 && d<=15 ?"Low to moderate swelling (10% to 15%)" :d>15 && d<=20?"Moderate to high swelling (15% to 20%)" :d>20?"Very high swelling (>20%)":"").filter((item, pos)=>  axisInput?.y.map(d => d>=-5 && d<=0?"Shrinkage ( -5%to0%)":d>0 && d<=10?"Swelling (0% to 10% )": d>10 && d<=15 ?"Low to moderate swelling (10% to 15%)" :d>15 && d<=20?"Moderate to high swelling (15% to 20%)" :d>20?"Very high swelling (>20%)":"").indexOf(item) == pos)}`,
      // text: concenteration.map(item=>item),
      name:item?.legend2,
      textposition: 'auto',
      mode: 'markers',
      customdata:resistance?.map(data=>data),
      hovertemplate:
    //  "%{xaxis.title.text}: %{x}<br>" +
     `${item?.legend2}: %{y}<br>` +
     // "Resistance: %{customdata}" +
     "<extra></extra>",
    //  marker:{
    //   line: {
    //     color:"blue"
    //   },
    //   size:1,
      
    //   color:"blue"
    //  }
     
     }
    ]}
    useResizeHandler={true}
    style={{ width: "100%", height: "100%" }}
  let layout = {{
    // barmode: 'stack',
    // barmode: 'group',
  
    legend: {"orientation": "h"},
    title: {
      text:item?.chartTitle,
      font: {
        family: 'Open Sans, verdana, arial, sans-serif',
        size: 14,
        color:"red",
        fontWeight:800
      },
      xref: 'paper',
      x: 0.05,
    },
    hovermode: 'closest' ,
    xaxis: {
      showticklabels: false,
      showline:false
    },
    yaxis: {
      title: item?.axis,
      titlefont: {
        family: 'Arial, sans-serif',
        size: 12,
        color: 'black'
      },
    },
    autosize: true,
    margin: {
      l: 50,
      r: 50,
      b: 100,
      t: 100,
      pad: 4,
    },
  
  }}
  />:<div style={{height:"400px",position:"relative"}}>
    <div>{item.chartTitle}</div>
    <div style={{position:"absolute",top:"50%",left:"50%",color:"red"}}>No Data</div>
  </div>
}
  </div>
    </Grid>
    ))}
  
  </Grid>

  
  :
  contourData?.length>0 && (props.chartType==='qualitativeContour' )?
  <Plot 
  var data = {[{
    z: contourData,
    // z:[[3, 3, 3], [10, 10, 3]],
    // x:[5, 10, 20],
    // y:[40, 40, 50], 
    x:axisInput?.x?.filter((i,idx) => axisInput?.x[idx-1] !== i),
    // y:axisInput?.y,
    y: axisInput?.y?.filter((i,idx) => axisInput?.y[idx-1] !== i),
    contours: {
      start: 0,
      end: 10,
      size: 1
    },
      colorbar:{
      ticks: 'outside',
      dtick: 1,
      tickwidth: 2,
      ticklen: 10,
      tickcolor: 'grey',
      showticklabels: true,
      tickfont: {
        size: 15
      },
      xpad: 50
    },
    colorscale: [
          [0.000, "silver"],
          [0.085, "silver"],
          [0.111, "#D93028"],
          [0.222, "#F36642"],
          [0.333, "#FBAB62"],
          [0.444, "#FDE58F"],
          [0.556, "#D8EE8A"],
          [0.667, "#A3D969"],
          [0.778, "#60BB60"],
          [0.889, "#18934D"],
          [0.989, "#066D36"],
          [1.000, "#066D36"]
        ],
    // y:[...new Set(axisInput?.y)],
    type: 'contour',
    hovertemplate:
    "%{xaxis.title.text}: %{x}<br>" +
    "Temperature (\u00B0C): %{y}<br>" +
    "Chemical resistance: %{z}<br>" +
  
    // "Resistance: %{customdata}" +
    "<extra></extra>",
    colorbar:{
      title: 'Chemical Resistance',
      titleside: 'right',
      titlefont: {
        size: 14,
        family: 'Arial, sans-serif'
      }
    },
    
    line:{
 width:0
    },
    dx: 10,
    x0: 5,
    dy: 10,
    y0: 10
  } ]}
  useResizeHandler={true}
  style={{ width: "100%", height: "100%" }}
  let layout = {{
    legend: {"orientation": "h"},

    title: {
      text:`${inputFieldsData.map(x=>`${x.displayName}:${x.value} `)}`,
      font: {
        family: 'Open Sans, verdana, arial, sans-serif',
        size: 14,
        color:"black",
      },
      xref: 'paper',
      x: 0.05,
    },
    hovermode: 'closest' ,
    xaxis: {
      title: {
        text:'Concentration (vol%) ',
        standoff:10 
      }, 
      
      titlefont: {
        family: 'Arial, sans-serif',
        size: 18,
        color: 'black'
      },
    },
    yaxis: {
      title: 'Temperature (\u00B0C)',
      titlefont: {
        family: 'Arial, sans-serif',
        size: 18,
        color: 'black'
      },
    },
    
    autosize: true,

  }}
/>
:props.chartType==='qualitativeContourHighTemp' || props.chartType==='dataMaterialsCO2'  ||props.chartType==='dataImpurityOnly'?
  <Plot 
  var data = {[{
    z: contourData?.z,
    x: contourData?.x,
    y: contourData?.y,
    contours: {
      start: 0,
      end: 10,
      size: 1
    },
      colorbar:{
      ticks: 'outside',
      dtick: 1,
      tickwidth: 2,
      ticklen: 10,
      tickcolor: 'grey',
      showticklabels: true,
      tickfont: {
        size: 15
      },
      xpad: 50
    },
    colorscale: [
          [0.000, "silver"],
          [0.085, "silver"],
          [0.111, "#D93028"],
          [0.222, "#F36642"],
          [0.333, "#FBAB62"],
          [0.444, "#FDE58F"],
          [0.556, "#D8EE8A"],
          [0.667, "#A3D969"],
          [0.778, "#60BB60"],
          [0.889, "#18934D"],
          [0.989, "#066D36"],
          [1.000, "#066D36"]
        ],
    // y:[...new Set(axisInput?.y)],
    type: 'contour',
    hovertemplate:
    "%{xaxis.title.text}: %{x}<br>" +
    "Temperature (\u00B0C): %{y}<br>" +
    "Chemical resistance: %{z}<br>" +
  
    // "Resistance: %{customdata}" +
    "<extra></extra>",
    colorbar:{
      title: 'Chemical Resistance',
      titleside: 'right',
      titlefont: {
        size: 14,
        family: 'Arial, sans-serif'
      }
    },
    
    line:{
 width:0
    },
    dx: 10,
    x0: 5,
    dy: 10,
    y0: 10
  } ]}
  useResizeHandler={true}
  style={{ width: "100%", height: "100%" }}
  let layout = {{
    legend: {"orientation": "h"},

    title: {
      text:`${inputFieldsData.map(x=>`${x.displayName}:${x.value} `)}`,
      font: {
        family: 'Open Sans, verdana, arial, sans-serif',
        size: 14,
        color:"black",
      },
      xref: 'paper',
      x: 0.05,
    },
    hovermode: 'closest' ,
    xaxis: {
      title: {
        text:'Concentration (vol%) ',
        standoff:10 
      }, 
      
      titlefont: {
        family: 'Arial, sans-serif',
        size: 12,
        color: 'black'
      },
    },
    yaxis: {
      title: 'Temperature (\u00B0C)',
      titlefont: {
        family: 'Arial, sans-serif',
        size: 11,
        color: 'black'
      },
    },
    margin: {
      l: 50,
      r: 50,
      b: 100,
      t: 100,
      pad: 20
    },
    automargin: true,

  }}
/>
  :props.chartType==='quantitativesealsco2'?
  <Grid container spacing={2} >
  {multipleInput.map((item,i)=>
  {

    return(
  <Grid item xs={6} >
    
  <div style={{border:"1px solid silver",width:"100%",height:"100%",display:"flex",flexDirection:"column"}}>
 
    <Plot  
    var data = {[
      {
      x: item?.x1,
      y:item?.y1,
      showlegend:true,
      type: 'bar',
      name:"Control group",
      textposition: 'auto',
      mode: 'markers',
      customdata:resistance?.map(data=>data),
      hovertemplate:
     "%{xaxis.title.text}: %{x}<br>" +
     "%{yaxis.title.text}: %{y}<br>" +
     "<extra></extra>",
     marker:{
      line: {
        color:"rgb(16,82,177)"
      },
      size:1,
       color:"rgb(16,82,177)"
     }
     
     },
      {
      x: item?.x2,
      y:item?.y2,
      showlegend:true,
      type: 'bar',
      name:"Gas",
      textposition: 'auto',
      mode: 'markers',
      customdata:resistance?.map(data=>data),
      hovertemplate:
     "%{xaxis.title.text}: %{x}<br>" +
     "%{yaxis.title.text}: %{y}<br>" +
     "<extra></extra>",
     marker:{
      line: {
        color:"rgb(135,134,135)"
      },
      size:1,
      color:"rgb(135,134,135)"
     }
     
     },
      {
      x: item?.x3,
      y:item?.y3,
      showlegend:true,
      type: 'bar',
      name:"Liquid",
      textposition: 'auto',
      mode: 'markers',
      customdata:resistance?.map(data=>data),
      hovertemplate:
     "%{xaxis.title.text}: %{x}<br>" +
     "%{yaxis.title.text}: %{y}<br>" +
     "<extra></extra>",
     marker:{
      line: {
        color:"rgb(144,198,255)"
      },
      size:1,
       color:"rgb(144,198,255)"
     }
     
     },
      {
      x: item?.x4,
      y:item?.y4,
      showlegend:true,
      type: 'bar',
      name:"initial state",
      textposition: 'auto',
      mode: 'markers',
      customdata:resistance?.map(data=>data),
      hovertemplate:
     "%{xaxis.title.text}: %{x}<br>" +
     "%{yaxis.title.text}: %{y}<br>" +
     "<extra></extra>",
     marker:{
      line: {
        color:"rgb(241,183,159"
      },
      size:1,
       color:"rgb(241,183,159"
     }
     
     },
    
    ]}
    useResizeHandler={true}
    style={{ width: "100%", height: "100%" }}
  let layout = {{
    barmode: 'group',
    legend: {"orientation": "h"},
    title: {
      text:item?.chartTitle,
      font: {
        family: 'Open Sans, verdana, arial, sans-serif',
        size: 12,
        color:"red",
        fontWeight:800
      },
      xref: 'paper',
      x: 0.05,
    },
    hovermode: 'closest' ,
    xaxis: {
      title: item?.xaxis,

      titlefont: {
        family: 'Arial, sans-serif',
        size: 10,
        color: 'black'
      },
    },
    yaxis: {
      title: item?.yaxis,
      titlefont: {
        family: 'Arial, sans-serif',
        size: 12,
        color: 'black'
      },
    },
    autosize: true,
    margin: {
      "l": 60,
      "r": 20,
      "t": 30,
      "b": 50,
      pad: 4,
    },
  
  }}
  />
</div>
</Grid>
 )})} 
  </Grid>
:props.chartType=='volChangePressure'?
<Plot 
  
var data = {[trace1,trace7,trace8,trace2 ] }
useResizeHandler={true}
style={{ width: "100%", height: "100%" }}
let layout = {{
legend: {"orientation": "h"},
title: {
  text:`${inputFieldsData.map(x=>`${x.displayName}:${x.value} `)}`,
  font: {
    family: 'Open Sans, verdana, arial, sans-serif',
    size: 14,
    color:"black",
  },
  xref: 'paper',
  x: 0.05,
},
hovermode: 'closest' ,
xaxis: {
  title: {
    text:props.chartType==='chemicalCompatibilitySelectExposure'?"Trade name" :props.chartType==='volChangeTemp'? `Temperature (&#176;C)` :props.chartType==='volChangeTime'? "Duration of exposure (h)" : props.chartType==='volChangePressure'?"Pressure (bar)":props.chartType==='DataImpurityCO2'?"Impurity":props.chartType==='dataConsolv'?'Exposure time(day)':'Exposure medium ',
    standoff:10 
  }, 
  titlefont: {
    family: 'Arial, sans-serif',
    size: 18,
    color: 'black'
  },
},
yaxis: {
  title: props.chartType==='volChangeTime' || props.chartType==='volChangeTemp' || props.chartType==='volChangePressure' || props.chartType==='DataImpurityCO2'?"Volume change(%)" :props.chartType==='dataConsolv'?axisInput?.z: 'Change (%)',
  titlefont: {
    family: 'Arial, sans-serif',
    size: 18,
    color: 'black'
  },
},
autosize: true,
}}
/>:
  <Plot 
  
  var data = {[trace1,trace7,trace8 ] }
  useResizeHandler={true}
  style={{ width: "100%", height: "100%" }}
let layout = {{
  legend: {"orientation": "h"},
  title: {
    text:`${inputFieldsData.map(x=>`${x.displayName}:${x.value} `)}`,
    font: {
      family: 'Open Sans, verdana, arial, sans-serif',
      size: 14,
      color:"black",
    },
    xref: 'paper',
    x: 0.05,
  },
  hovermode: 'closest' ,
  xaxis: {
    title: {
      text:props.chartType==='chemicalCompatibilitySelectExposure'?"Trade name" :props.chartType==='volChangeTemp'? `Temperature (&#176;C)` :props.chartType==='volChangeTime'? "Duration of exposure (h)" : props.chartType==='volChangePressure'?"Pressure (bar)":props.chartType==='DataImpurityCO2'?"Impurity":props.chartType==='dataConsolv'?'Exposure time(day)':'Exposure medium ',
      standoff:10 
    }, 
    titlefont: {
      family: 'Arial, sans-serif',
      size: 18,
      color: 'black'
    },
  },
  yaxis: {
    title: props.chartType==='volChangeTime' || props.chartType==='volChangeTemp' || props.chartType==='volChangePressure' || props.chartType==='DataImpurityCO2'?"Volume change(%)" :props.chartType==='dataConsolv'?axisInput?.z: 'Change (%)',
    titlefont: {
      family: 'Arial, sans-serif',
      size: 18,
      color: 'black'
    },
  },
  autosize: true,
}}
/>
}
</div>
  </Grid>
  {props.chartType==='qualitativeContour' && contourData?.length===0?
   <Alert style={{display:"flex",justifyContent:"center",marginTop:"2%",width:"100%"}} severity="error">
   
   No Data Available for inputs— <strong>check it out!</strong>
 </Alert> :
 <>
 <Grid item xs={12}>
   
 {filteredPosts && tableHeaders ?

<>
 <div id='divCSVDownload' style={{ float: 'right',marginTop:"20px" }}>
   <CSVLink filename={"DP" + '.csv'} data={filteredPosts} headers={updatedHeader}>
     <Button variant="contained" color="primary">
       Export Excel
     </Button>
   </CSVLink>
 </div>
 <br></br>
 <br></br>
 <div className={classes.root} >
   <Paper className={classes.paper}>
     <TableContainer>
       <Table
         className={classes.table}
         aria-labelledby="tableTitle"
         aria-label="enhanced table"
       >

         <TableHead>
             
         <TableRow >
           {updatedHeader?.map((data,i)=>(
           
           <TableCell key={i} align="right">{data}</TableCell>
          
           ))}
         </TableRow>
       </TableHead>
         <TableBody>
           {filteredPosts&&stableSort(filteredPosts)
             ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
             ?.map((row, index) => {
               const labelId = `enhanced-table-checkbox-${index}`;

               return (
                 <TableRow
                   hover

                   tabIndex={-1}
                   key={row.name}
                 >

                   {tableHeaders.map((element, j) =>
                     <TableCell align="center">{row[`${element}`]}</TableCell>
                   )}

                 </TableRow>
               );
             })}

         </TableBody>
       </Table>
     </TableContainer>
     <TablePagination
       rowsPerPageOptions={[5, 10, 25]}
       component="div"
       count={filteredPosts ? filteredPosts?.length : 0}
       rowsPerPage={rowsPerPage}
       page={page}
       onChangePage={handleChangePage}
       onChangeRowsPerPage={handleChangeRowsPerPage}
     />

   </Paper>



 </div>
</>
: <></>}
 </Grid>
 </>
}
  
  </Grid>
   </Box>:  
   loading&&
   <Box sx={{ display: 'flex' }} style={{display:"flex",margin:"50px",justifyContent:"center"}}>
      <CircularProgress />
    </Box>
}
   </>
  )
}


export default Quantitative

